import {
  Box,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React, { useEffect } from 'react';
import { FlyerConfigs } from './flyerConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { GENERATE_MATERIALS } from '../../constants/route-path';
import FLYERHEROBG from '../../Assets/images/flyer-hero-bg.png';
import VerifiedIcon from '@mui/icons-material/Verified';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import { TRANSLATIONS } from '../../Utils/translations';
import SQFTICON from '../../Assets/images/sqft-icon.svg';
import BEDICON from '../../Assets/images/bedroom-icon.svg';
import BALCONYICON from '../../Assets/images/balcony-icon.svg';
import BATHICON from '../../Assets/images/bathroom-icon.svg';
import { useTranslation } from 'react-i18next';

const GenerateMaterialContainer = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const propValue = searchParams.get('pid');
  const theme = useTheme();
  const lgBreakpointMatches = useMediaQuery(theme.breakpoints.up('lg'));
  const { t } = useTranslation();

  const {
    cover_picture,
    property_sale_type,
    property_title,
    property_status,
    locality,
    property_type,
    furnish_type,
    built_up_area,
    bathroom_count,
    bedroom_count,
    balcony_count,
  } = location.state;

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top
  }, []);

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '85vh',
        mt: '100px',
        background: '#ffffff',
      }}
    >
      <Box
        sx={{
          backgroundImage: `url(${FLYERHEROBG})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          padding: '20px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '97%',
          margin: '0 auto',
          borderRadius: '20px',
        }}
      >
        <Typography
          sx={{
            fontSize: '22px',
            color: '#FEFEFE',
            fontWeight: 600,
            textAlign: 'center',
            py: 3,
          }}
        >
          Flyer Templates
        </Typography>
        <Typography
          sx={{
            color: '#FEFEFE',
            fontSize: '14px',
            textAlign: 'center',
            width: '70%',
            margin: '30px auto',
            mt: '5px',
          }}
        >
          Each poster template is designed to showcase your property
          effortlessly. Select from a range of templates, and let AIRA instantly
          generate a stunning poster using your listing details. Create
          eye-catching property posters in one click and enhance your marketing
          in seconds!
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          boxShadow: '0px 1px 30.1px 0px rgba(74, 74, 74, 0.25)',
          border: '0.5px solid rgba(210, 210, 210, 1)',
          borderRadius: '32px',
          width: '85%',
          padding: { lg: '12px', xs: '4px' },
          margin: { lg: '20px auto', xs: '14px auto' },
          flexWrap: 'nowrap',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            width: '100%',
            padding: { lg: '5px 10px', xs: '4px' },
            paddingBottom: '10px',
          }}
        >
          <Box
            sx={{
              width: { lg: '55%', xs: '100%' },
              display: 'flex',
              flexDirection: { lg: 'row', xs: 'column' },
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
              paddingRight: { lg: '10px', xs: '0px' },
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                flexDirection: 'column',
              }}
            >
              <img
                width={lgBreakpointMatches ? 130 : '100%'}
                alt="profile"
                src={cover_picture}
                style={{
                  borderRadius: '20px',
                  margin: '10px 0',
                  marginRight: '10px',
                  height: '120px',
                  objectFit: 'cover',
                }}
              />
              {property_sale_type && (
                <Typography
                  sx={{
                    color: '#7D7D7D',
                    fontSize: '14px',
                    fontWeight: 400,
                    mx: '5px',
                    //   mb:1
                  }}
                >
                  {property_sale_type}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography
                sx={{
                  color: '#474747',
                  fontSize: '18px',
                  fontWeight: 700,
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  mt: '6px',
                  ml: '3px',
                }}
              >
                {property_title}

                {property_status === 'Verified' && (
                  <VerifiedIcon
                    sx={{
                      color: '#600DB4',
                      width: '18px',
                      height: '18px',
                      ml: '5px',
                    }}
                  />
                )}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  my: '10px',
                }}
              >
                {locality && (
                  <LocationOnOutlinedIcon
                    sx={{
                      color: '#7D7D7D',
                      width: '18px',
                      height: '18px',
                      mr: '3px',
                    }}
                  />
                )}
                <Typography
                  sx={{
                    color: '#7D7D7D',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  {locality}
                </Typography>
              </Box>
              <Box
                sx={{
                  my: '10px',
                  ml: '3px',
                  display: 'flex',
                  flexDirection: { lg: 'row', xs: 'column' },
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
              >
                <Typography
                  sx={{
                    color: '#7D7D7D',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  {property_type}
                </Typography>
                {lgBreakpointMatches && furnish_type && (
                  <Divider
                    orientation="vertical"
                    variant="middle"
                    flexItem
                    sx={{ mx: 1, height: '15px' }}
                  />
                )}
                <Typography
                  sx={{
                    color: '#7D7D7D',
                    fontSize: '14px',
                    fontWeight: 400,
                  }}
                >
                  {furnish_type}
                </Typography>
              </Box>
            </Box>
          </Box>

          {lgBreakpointMatches && (
            <Divider
              orientation="vertical"
              variant="middle"
              sx={{ opacity: 0.3 }}
              flexItem
            />
          )}

          <Box
            sx={{
              width: { lg: '40%', xs: '100%' },
              padding: '10px',
              mt: 0,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
              flexWrap: 'wrap',
            }}
          >
            <Box
              sx={{
                width: { lg: '41%', xs: '100%' },
                background: '#E6F4F9',
                margin: '7px',
                p: 1,
                borderRadius: '8px',
              }}
            >
              <img src={SQFTICON} alt="icon" width={20} height={20} />
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#474747',
                  my: '5px',
                  display: 'flex',
                  flexDirection: 'row',
                }}
              >
                <strong style={{ marginRight: '4px', overflow: 'hidden' }}>
                  {built_up_area}
                </strong>{' '}
                sq.ft.{' '}
              </Typography>
            </Box>
            {bathroom_count && (
              <Box
                sx={{
                  width: { lg: '41%', xs: '100%' },
                  margin: '7px',
                  background: '#755FFE26',
                  borderRadius: '8px',
                  p: 1,
                }}
              >
                <img src={BATHICON} alt="icon" width={20} height={20} />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#474747',
                    my: '5px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <strong style={{ marginRight: '4px', overflow: 'hidden' }}>
                    {bathroom_count}
                  </strong>{' '}
                  {t(TRANSLATIONS.TITLES.BATHROOMS)}
                </Typography>
              </Box>
            )}
            {bedroom_count && (
              <Box
                sx={{
                  width: { lg: '41%', xs: '100%' },
                  margin: '7px',
                  borderRadius: '8px',
                  background: '#FFEEEF',
                  p: 1,
                }}
              >
                <img src={BEDICON} alt="icon" width={20} height={20} />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#474747',
                    my: '5px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <strong style={{ marginRight: '4px', overflow: 'hidden' }}>
                    {bedroom_count}
                  </strong>{' '}
                  {t(TRANSLATIONS.TITLES.BEDROOMS)}
                </Typography>
              </Box>
            )}
            {balcony_count && (
              <Box
                sx={{
                  width: { lg: '41%', xs: '100%' },
                  margin: '7px',
                  p: 1,
                  background: '#FFA42526',
                  borderRadius: '8px',
                }}
              >
                <img src={BALCONYICON} alt="icon" width={20} height={20} />
                <Typography
                  sx={{
                    fontSize: '14px',
                    color: '#474747',
                    my: '5px',
                    display: 'flex',
                    flexDirection: 'row',
                  }}
                >
                  <strong style={{ marginRight: '4px' }}>
                    {balcony_count}
                  </strong>{' '}
                  {t(TRANSLATIONS.TITLES.BALCONY)}
                </Typography>
              </Box>
            )}
          </Box>

          {/* {lgBreakpointMatches && (
          <Divider
            orientation="vertical"
            variant="middle"
            sx={{ opacity: 0.3 }}
            flexItem
          />
        )} */}

          {/* {lgBreakpointMatches && <Amenities amenities={amenities} />} */}
        </Box>

        {/* <Divider sx={{ width: '100%', opacity: 0.6 }} /> */}

        {/* {!lgBreakpointMatches && (
        <>
          <Amenities amenities={amenities} />{' '}
          <Divider sx={{ width: '100%', opacity: 0.6 }} />
        </>
      )} */}
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'flex-start',
          justifyContent: 'center',
          flexWrap: 'wrap',
          width: '90%',
          margin: '10px auto',
        }}
      >
        {FlyerConfigs.map((item, index) => (
          <Box
            onClick={() =>
              navigate(`${GENERATE_MATERIALS}?pid=${propValue}&tid=${index}`)
            }
            key={item.id}
            sx={{
              m: '10px',
              width: '22%',
              height: '340px',
              background: '#EFEFEF',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: '5px 30px',
              cursor: 'pointer',
            }}
          >
            <img
              src={item.image}
              style={{ width: '90%', height: '80%', objectFit: 'contain' }}
              alt="template"
            />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default GenerateMaterialContainer;
