import {
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { ToWords } from 'to-words';
import { colorsEnums } from '../constants/enums';

const toWords = new ToWords();

export default function TextFieldWithLabel({
  placeholder = '',
  label = '',
  required = false,
  hide = false,
  type = '',
  value = '',
  convertNumberToWord = false,
  lg = '',
  marginBottom = '',
  icon = null,
  ...props
}) {
  if (hide) {
    return null;
  }

  return (
    <Grid
      item
      lg={lg || 5.5}
      xs={12}
      sx={{ marginBottom: marginBottom || '32px' }}
    >
      <InputLabel sx={{ margin: '12px 0' }}>
        {label}
        {required && <span style={{ color: colorsEnums.error }}>*</span>}
      </InputLabel>
      <TextField
        sx={{ borderRadius: '24px !important', width: '100%' }}
        placeholder={placeholder}
        variant="outlined"
        value={value}
        type={type}
        {...props}
        inputProps={{
          ...(type === 'number' && {
            min: 0,
            style: { MozAppearance: 'textfield' },
          }),
          ...(type === 'date' && {
            min: '2023-01-01',
            max: '2100-12-31',
          }),
        }}
        InputProps={{
          ...(type === 'number' && {
            inputMode: 'numeric', // Show numeric keyboard on mobile
            style: { appearance: 'none' },
          }),
          ...(icon && {
            startAdornment: (
              <InputAdornment position="start">{icon}</InputAdornment>
            ),
          }),
        }}
      />

      {type === 'number' && convertNumberToWord && value && (
        <Typography
          sx={{ fontSize: '14px', marginTop: '8px', color: '#7D7D7D' }}
        >
          {toWords.convert(value, { currency: true })}
        </Typography>
      )}
    </Grid>
  );
}
