import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Divider,
  Grid,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import './viewprofile.css';
import CircleIcon from '@mui/icons-material/Circle';
import UpdateProfile from '../UpdateProfile/UpdateProfile';
import apis from '../../../Services/api-services';
import GenerateQRModal from './components/GenerateQR';
import dayjs from 'dayjs';
import LoaderComponent from '../../../components/loader';
import { useLocation, useNavigate } from 'react-router-dom';
import { numDifferentiation } from '../../../Utils/helperUtils';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import AgentProfilePicture from '../components/AgentProfilePicture';
import { userActions } from '../../../Store/userSlice';
import PropertyDetail from '../../../components/propertyDetail';
import {
  IconBrandFacebook,
  IconBrandInstagram,
  IconBrandLinkedinFilled,
  IconBriefcaseFilled,
  IconBuilding,
  IconHomeFilled,
  IconKeyFilled,
  IconMapPin,
  IconRosetteDiscountCheckFilled,
} from '@tabler/icons-react';
import ImagesLinks from '../../../Utils/ImagesLinks';
import PageTitle from '../../../components/pageTitle';
import { handleProfileRedirection } from '../../../constants/enums';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { TRANSLATIONS } from '../../../Utils/translations';
import { useTranslation } from 'react-i18next';
import Meta from '../../../components/Meta';

const ProfileStat = ({ label, value, customerId, icon, iconBackground }) => (
  <Grid
    item
    lg={customerId ? 12 : 2.8}
    xs={12}
    sx={{
      display: 'flex',
      alignItems: 'center',
      gap: '.5rem',
    }}
  >
    <Box
      sx={{
        background: iconBackground,
        borderRadius: '4px',
        width: '35px',
        height: '35px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {icon}
    </Box>

    <Box>
      <Typography
        sx={{ color: '#7D7D7D', fontSize: '14px', fontWeight: '600' }}
      >
        {value}
      </Typography>
      <Typography
        sx={{ color: '#474747', fontSize: '12px', fontWeight: '500' }}
      >
        {label}
      </Typography>
    </Box>
  </Grid>
);

const ChipGroup = ({ city, areas, customerId }) => (
  <Box
    sx={{
      marginTop: '1rem',
    }}
  >
    <Typography
      sx={{
        color: '#474747',
        fontSize: '17px',
        fontWeight: '600',
        width: customerId ? '100%' : '23%',
        marginBottom: '16px',
      }}
    >
      {city}
    </Typography>
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        flexWrap: 'wrap',
        width: customerId ? '100%' : { lg: '75%', xs: '100%' },
        gap: 1,
      }}
    >
      {areas?.map((area, index) => (
        <Chip
          key={area}
          label={area}
          sx={{
            color: '#474747',
            marginBottom: '.5rem',
            borderRadius: '16px',
            background: 'transparent',
            border: '1px solid #D2D2D2',
          }}
        />
      ))}
    </Box>
  </Box>
);

const LanguageBox = ({ symbol, language }) => (
  <Typography
    sx={{
      color: '#474747',
      fontSize: '14px',
      fontWeight: '500',
      marginLeft: '.5rem',
      marginBottom: '.5rem',
      backgroundColor: '#F4F4F4',
      padding: '6px 20px',
      borderRadius: '16px',
    }}
  >
    {language}
  </Typography>
);

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const testimonial = [];

const ViewProfile = ({
  customerId,
  preview = false,
  data,
  setActiveStep,
  uploadedImage,
  sidePreview = false,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [selectedPropertyID, setSelectedPropertyID] = useState(null);
  const [openPropertyDetail, setOpenPropertyDetail] = useState(false);

  const loggedInAgentId = useSelector(
    (state) => state.userDetails.userId || {}
  );
  const { isLoggedIn } = useSelector((state) => state.login || {});
  const agentId = new URLSearchParams(location.search).get('customer_id');
  const propertyIds = new URLSearchParams(location.search).get('properties');
  const parsedPropertyIds = propertyIds?.split(',');
  const locality = new URLSearchParams(location.search).get('locality');
  const city = new URLSearchParams(location.search).get('city');

  const [loader, setLoader] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [agentDetails, setAgentDetails] = useState(null);
  const [showGenerateQRModal, setShowGenerateQRModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [properties, setProperties] = useState({
    active: [],
    closed: [],
  });
  const [selectedPropertyTab, setSelectedPropertyTab] = useState(0);
  const [recommendedAgentProperties, setRecommendedAgentProperties] = useState({
    active: { recommended: [], others: [] },
    closed: [],
  });
  const [previewDescription, setPreviewDescription] = useState('');

  const MAX_LENGTH = 280;
  const areasOfOperation = JSON.parse(
    JSON.parse(agentDetails?.area_of_operation || null)
  );

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top

    if (preview) {
      handleGenerateDescription();
    } else {
      if (agentId || customerId) {
        getAgentDetailsById(agentId || customerId);
      } else {
        getLoggedInAgentDetails();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preview, agentId, customerId]);

  useEffect(() => {
    const ids = propertyIds?.split(',');

    if (ids?.length && agentId) {
      getAgentRecommendedProperties(ids);
    } else if (agentId || customerId) {
      getPropertiesById(agentId || customerId);
    } else {
      getPropertiesById(loggedInAgentId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, agentId, customerId, location]);

  const getAgentRecommendedProperties = async (ids) => {
    setLoader(true);
    const response = await apis.property.getRecommendedAgentProperties(
      agentId,
      ids
    );

    setRecommendedAgentProperties(response?.data?.profile);

    setLoader(false);
  };

  const getLoggedInAgentDetails = async () => {
    setLoader(true);
    const response = await apis.agent.getAgentProfileDetails();

    const agent = response?.data?.profile;
    setAgentDetails(agent);

    dispatch(userActions.setUserId(agent?.customer_id));
    dispatch(userActions.setUserName(agent?.full_name));
    dispatch(userActions.setIsUserVerified(agent?.is_verified));
    dispatch(userActions.setUserAvatar(agent?.profile_picture || ''));

    setLoader(false);
  };

  const handleLinkClick = (item) => {
    if (item) {
      try {
        const normalizedUrl =
          item.startsWith('http://') || item.startsWith('https://')
            ? item
            : `https://${item}`;

        const url = new URL(normalizedUrl);

        window.open(url.toString(), '_blank', 'noopener,noreferrer');
      } catch (e) {
        toast.error('Invalid link');
      }
    }
  };

  const getPropertiesById = async (agentId) => {
    const response = await apis.agent.getPropertiesByAgentId(agentId);
    setProperties(response?.data?.properties || { active: [], closed: [] });
  };

  const getAgentDetailsById = async (agentId) => {
    setLoader(true);
    const response = await apis.agent.viewAgentProfileQR({
      agentId,
    });

    setAgentDetails(response?.data?.profile);

    setLoader(false);
  };

  const updateAgentProfilePicture = async (image) => {
    setLoader(true);

    const form = new FormData();
    form.append('profile_picture', image);

    const response = await apis.agent.setAgentProfilePicture(form);
    if (response?.data) {
      toast.success(
        t(TRANSLATIONS.PARAGRAPHS.PROFILE_PICTURE_UPDATED_SUCCESSFULLY)
      );
      await getLoggedInAgentDetails();
    }
    setLoader(false);
  };

  const toggleDrawer = (newOpen) => () => {
    setOpenPropertyDetail(newOpen);
  };

  const handleSelectedProperty = (id, target) => {
    if (isLoggedIn) {
      if (target?.id !== 'property-item-save') {
        setSelectedPropertyID(id);
        setOpenPropertyDetail(true);
      }
    }
  };

  const specializationCategory = {
    'Residential Sales': { backgroundColor: '#F3EBFB', color: '#67399D' },
    'Residential Rent': {
      backgroundColor: 'rgba(255, 164, 37, 0.2)',
      color: '#FFA425',
    },
    'Residential Leasing': {
      backgroundColor: 'rgba(230, 244, 249, 1)',
      color: '#16C2FF',
    },
    'Commercial Sales': { backgroundColor: '#E2EBFA', color: '#336CAB' },
    'Commercial Rent': { backgroundColor: '#F4F0DF', color: '#A38D2D' },
  };

  const handleGenerateDescription = async () => {
    const params = {
      full_name: data?.name || '',
      specialization: data?.specialization || [],
      languages: data?.languages || [],
      area_of_operation: JSON.stringify(data?.areasOfOperation || {}) || [],
      years_of_experience: data?.experience || 0,
      company_name: data?.companyName || '',
    };

    const response = await apis.agent.regenerateAgentDescription(params);

    if (response?.data?.status === 'success') {
      setPreviewDescription(response?.data?.agent_description);
    }
  };

  return (
    <>
      {loader && <LoaderComponent />}

      <Meta
        title={agentDetails?.full_name && agentDetails?.full_name + ' | vREfyd'}
        favIcon={agentDetails?.profile_picture}
        description={agentDetails?.user_description}
      />

      {preview && (
        <Box sx={{ marginBottom: '2rem' }}>
          <PageTitle
            title={t(TRANSLATIONS.TITLES.PREVIEW_AND_PERFECT)}
            description={t(TRANSLATIONS.PARAGRAPHS.QUICK_LOOK_PROFILE)}
            note={t(TRANSLATIONS.PARAGRAPHS.ENSURE_PROFILE_PERFECTION)}
          />
        </Box>
      )}

      <Box
        sx={{
          padding: preview ? 0 : sidePreview ? '2rem 0' : '8rem 0',
          background: '#FFFFFF',
        }}
      >
        <Box
          sx={{
            width: preview ? '100%' : { lg: '94%', xs: '96%' },
            margin: '0 auto',
          }}
        >
          <Box sx={{ padding: { lg: '2rem', xs: '0.5rem' } }}>
            {/* avatar, name, location */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: { xs: 'column', lg: 'row' },
                padding: { xs: '.5rem', lg: '4rem 3rem' },
                boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 1px',
                backgroundImage: `url(${ImagesLinks.profileCoverBg})`,
                backgroundRepeat: 'no-repeat',
                borderRadius: '16px',
                backgroundSize: '100%',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  ...(sidePreview
                    ? {
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }
                    : {}),
                }}
              >
                <AgentProfilePicture
                  userId={loggedInAgentId}
                  agentDetails={agentDetails}
                  updateAgentProfilePicture={updateAgentProfilePicture}
                  uploadedImage={uploadedImage}
                  preview={preview}
                />
              </Box>

              <Box sx={{ paddingLeft: { xs: '0rem', lg: '2rem' } }}>
                <Box
                  sx={{
                    display: 'flex',
                    gap: '2rem',
                    flexDirection: { xs: 'column', lg: 'row' },
                  }}
                >
                  <Typography
                    sx={{
                      color: '#474747',
                      fontSize: { lg: '2.5rem', xs: '2rem' },
                      fontWeight: '600',
                      lineHeight: '58.09px',
                      display: { lg: 'inline-block', xs: 'inline' },
                      maxWidth: { lg: '300px', xs: '100%' },
                    }}
                  >
                    {data?.name || agentDetails?.full_name}
                    {!preview && agentDetails?.is_verified && (
                      <IconRosetteDiscountCheckFilled
                        color="#600DB4"
                        sx={{
                          marginLeft: '.6rem',
                          right: '6px',
                        }}
                      />
                    )}
                  </Typography>

                  {/* edit and share button */}
                  <Box
                    sx={{
                      alignSelf: 'flex-end',
                    }}
                  >
                    <Box sx={{ display: 'flex' }}>
                      {agentDetails?.customer_id === loggedInAgentId && (
                        <Button
                          variant="outlined"
                          className="edit-profile-btn"
                          onClick={() => {
                            if (preview) {
                              setActiveStep((preview) => preview - 1);
                            } else {
                              setOpenEditModal(true);
                            }
                          }}
                        >
                          {t(TRANSLATIONS.TITLES.EDIT_PROFILE)}
                        </Button>
                      )}

                      {agentDetails?.is_verified && (
                        <Button
                          variant="contained"
                          className="share-profile-btn"
                          onClick={() => setShowGenerateQRModal(true)}
                        >
                          {t(TRANSLATIONS.TITLES.SHARE_PROFILE)}
                        </Button>
                      )}

                      {agentDetails?.customer_id === loggedInAgentId &&
                        agentDetails?.verification_status === 'Rejected' && (
                          <Button
                            variant="contained"
                            className="share-profile-btn"
                            sx={{ mx: '10px', cursor: 'pointer' }}
                            onClick={() => handleProfileRedirection(navigate)}
                          >
                            {t(TRANSLATIONS.TITLES.RE_INITIATE_VERIFICATION)}
                          </Button>
                        )}
                    </Box>
                  </Box>
                </Box>

                <>
                  {agentDetails?.verification_status === 'Initiated' && (
                    <Typography
                      sx={{
                        padding: '6px 20px',
                        background: '#D2D2D2',
                        borderRadius: '24px',
                        fontSize: '14px',
                        marginTop: '12px',
                        maxWidth: '200px',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.VERIFICATION_IN_PROGRESS)}{' '}
                    </Typography>
                  )}

                  {agentDetails?.verification_status === 'Rejected' && (
                    <Typography
                      sx={{
                        padding: '6px 20px',
                        background: '#FFEEEF',
                        color: '#A03C42',
                        borderRadius: '24px',
                        fontSize: '14px',
                        marginTop: '12px',
                        maxWidth: '200px',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'row',
                        textWrap: 'nowrap',
                      }}
                    >
                      <ErrorOutlineRoundedIcon
                        sx={{ color: '#A03C42', mr: '5px' }}
                      />

                      {t(TRANSLATIONS.TITLES.VERIFICATION_REJECTED)}
                    </Typography>
                  )}

                  {agentDetails?.customer_id === loggedInAgentId &&
                    (agentDetails?.verification_status === 'Initiated' ||
                      agentDetails?.verification_status === 'Re-Initiated') && (
                      <Typography
                        sx={{
                          justifySelf: 'end',
                          color: '#7D7D7D',
                          fontWeight: '600',
                          marginTop: '12px',
                        }}
                      >
                        {t(TRANSLATIONS.TITLES.VERIFICATION)}{' '}
                        {agentDetails?.verification_status || 'requested'} on:{' '}
                        {dayjs(agentDetails?.verification_initiated_on).format(
                          'DD MMM YYYY, hh:mm A'
                        )}
                      </Typography>
                    )}
                </>

                <Grid
                  container
                  sx={{
                    marginTop: '1rem',
                    justifyContent: 'space-between',
                    border: { xs: 0, lg: '1px solid #EFEFEF' },
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 1px',
                    borderRadius: '16px',
                    padding: '10px',
                    rowGap: { xs: '2rem', lg: '.5rem' },
                    width: '100%',
                    minWidth: { lg: customerId ? '100%' : '876px', xs: '0' },
                    background: '#ffffff',
                  }}
                >
                  {(data?.companyName || agentDetails?.brand_name) && (
                    <ProfileStat
                      label={t(TRANSLATIONS.TITLES.COMPANY)}
                      value={
                        data?.companyName || agentDetails?.brand_name || '-'
                      }
                      customerId={customerId}
                      icon={<IconBuilding size={20} color="#FFA425" />}
                      iconBackground="rgba(255, 164, 37, 0.2)"
                    />
                  )}

                  <ProfileStat
                    label={t(TRANSLATIONS.SUB_TITLES.YEARS_OF_EXPERIENCE)}
                    value={
                      data?.experience || agentDetails?.years_of_experience || 0
                    }
                    customerId={customerId}
                    icon={<IconBriefcaseFilled size={20} color="#16C2FF" />}
                    iconBackground="#E6F4F9"
                  />
                  <ProfileStat
                    label={t(TRANSLATIONS.TITLES.CLOSED_DEALS)}
                    value={
                      (agentId && parsedPropertyIds?.length
                        ? recommendedAgentProperties?.closed?.length
                        : properties?.closed?.length) || 0
                    }
                    customerId={customerId}
                    icon={<IconKeyFilled size={20} color="#FF5A5E" />}
                    iconBackground="#FFEEEF"
                  />
                  <ProfileStat
                    label={t(TRANSLATIONS.TITLES.ACTIVE_LISTING)}
                    value={
                      (agentId && parsedPropertyIds?.length
                        ? recommendedAgentProperties?.active?.recommended
                            ?.length
                        : properties?.active?.length) || 0
                    }
                    customerId={customerId}
                    icon={<IconHomeFilled size={20} color="#600DB4" />}
                    iconBackground="#F5EDFF"
                  />
                </Grid>
              </Box>
            </Box>

            <Grid
              container
              sx={{
                justifyContent: 'space-between',
                marginTop: '1rem',
                alignItems: 'flex-start',
              }}
            >
              {/* Left side */}
              <Grid item container lg={5} sm={12}>
                {/* about and specializations */}
                <Grid
                  item
                  lg={12}
                  xs={12}
                  sx={{
                    padding: '1rem',
                    borderRadius: '8px',
                    margin: '1rem 0 !important',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 1px',
                    border: '1px solid #EFEFEF',
                  }}
                >
                  {/* about */}
                  <>
                    <Typography
                      sx={{
                        marginBottom: '1rem',
                        color: '#7D7D7D',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      {t(TRANSLATIONS.TITLES.ABOUT)}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        color: '#474747',
                        textAlign: ' justify',
                        backgroundColor: '#fff',
                      }}
                    >
                      {isExpanded
                        ? preview
                          ? previewDescription
                          : agentDetails?.user_description
                        : preview
                        ? previewDescription?.slice(0, MAX_LENGTH)
                        : agentDetails?.user_description?.slice(0, MAX_LENGTH)}

                      {(preview
                        ? previewDescription?.length
                        : agentDetails?.user_description?.length) >
                        MAX_LENGTH && (
                        <>
                          {!isExpanded && '...'}

                          <a
                            href="##"
                            onClick={(e) => {
                              e.preventDefault();
                              setIsExpanded(!isExpanded);
                            }}
                            style={{
                              textDecoration: 'none',
                              color: '#600DB4',
                              marginLeft: '5px',
                              fontWeight: 600,
                            }}
                          >
                            {isExpanded ? 'see less' : 'see more'}
                          </a>
                        </>
                      )}
                    </Typography>
                  </>

                  <Divider sx={{ margin: '1.5rem 0', color: '#D2D2D2' }} />

                  {/* specialization */}
                  <>
                    <Typography
                      sx={{
                        color: '#7D7D7D',
                        fontSize: '16px',
                        fontWeight: '600',
                      }}
                    >
                      {t(TRANSLATIONS.SUB_TITLES.MY_SPECIALIZATION)}{' '}
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        marginTop: '1rem',
                        flexWrap: 'wrap',
                        gap: '8px',
                      }}
                    >
                      {(
                        data?.specialization || agentDetails?.specialization
                      )?.map((specialty, index) => (
                        <Box sx={{ marginBottom: '4px' }} key={specialty}>
                          <Typography
                            sx={{
                              color: specializationCategory[specialty]?.color,
                              fontSize: '14px',
                              fontWeight: '500',
                              backgroundColor:
                                specializationCategory[specialty]
                                  ?.backgroundColor,
                              padding: '6px 12px',
                              borderRadius: '16px',
                            }}
                          >
                            {specialty}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  </>

                  {/* additional details */}
                  {(data?.otherServices.length > 0 ||
                    agentDetails?.other_services?.length > 0) && (
                    <>
                      <Typography
                        sx={{
                          color: '#7D7D7D',
                          fontSize: '15px',
                          fontWeight: '600',
                          marginTop: '1rem',
                        }}
                      >
                        {t(TRANSLATIONS.TITLES.ADDITIONAL_SERVICES)}{' '}
                      </Typography>
                      <Box
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          marginTop: '1rem',
                          flexWrap: 'wrap',
                          gap: '8px',
                        }}
                      >
                        {(
                          data?.otherServices || agentDetails?.other_services
                        )?.map((service) => (
                          <Box sx={{ marginBottom: '4px' }} key={service}>
                            <Typography
                              sx={{
                                color: '#474747',
                                fontSize: '14px',
                                fontWeight: '500',
                                backgroundColor: '#E7ECF2',
                                padding: '6px 12px',
                                borderRadius: '16px',
                              }}
                            >
                              {service}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    </>
                  )}
                </Grid>

                {/* areas of operation */}
                <Grid
                  item
                  lg={12}
                  className="box"
                  sx={{
                    width: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 1px',
                    border: '1.5px solid #EFEFEF',
                    height: '100%',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#7D7D7D',
                      fontSize: '17px',
                      fontWeight: '600',
                    }}
                  >
                    {t(TRANSLATIONS.SUB_TITLES.AREAS_OF_OPERATION)}
                  </Typography>

                  {(data?.areasOfOperation || areasOfOperation)?.map((area) => (
                    <ChipGroup
                      city={area.city}
                      areas={area.places || []}
                      customerId={customerId}
                    />
                  ))}
                </Grid>

                {/* languages */}
                <Grid
                  item
                  lg={12}
                  className="box"
                  sx={{
                    width: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 1px',
                    border: '1px solid #EFEFEF',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#7D7D7D',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    {t(TRANSLATIONS.TITLES.LANGUAGE_S)}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '1rem',
                      flexWrap: 'wrap',
                    }}
                  >
                    {(data?.languages || agentDetails?.languages)?.map(
                      (language) => (
                        <LanguageBox key={language} language={language} />
                      )
                    )}
                  </Box>
                </Grid>

                {/* Social Media link */}
                <Grid
                  item
                  lg={12}
                  className="box"
                  sx={{
                    width: '100%',
                    boxShadow: 'rgba(0, 0, 0, 0.16) 0px 0px 1px',
                    border: '1px solid #EFEFEF',
                  }}
                >
                  <Typography
                    sx={{
                      color: '#7D7D7D',
                      fontSize: '16px',
                      fontWeight: '600',
                    }}
                  >
                    {t(TRANSLATIONS.SUB_TITLES.SOCIAL_LINKS)}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      marginTop: '1rem',
                      flexWrap: 'wrap',
                      gap: 2,
                    }}
                  >
                    <Box
                      onClick={() =>
                        handleLinkClick(
                          data?.fbPageLink || agentDetails?.facebook_link
                        )
                      }
                      sx={{
                        cursor:
                          data?.fbPageLink || agentDetails?.facebook_link
                            ? 'pointer'
                            : 'not-allowed',
                        background: '#F4F4F4',
                        width: '44px',
                        height: '44px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconBrandFacebook
                        color={
                          agentDetails?.facebook_link ? '#7E0DB4' : '#7D7D7D'
                        }
                        fontSize="small"
                      />
                    </Box>
                    <Box
                      onClick={() =>
                        handleLinkClick(
                          data?.instaPageLink || agentDetails?.instagram_link
                        )
                      }
                      sx={{
                        cursor:
                          data?.instaPageLink || agentDetails?.instagram_link
                            ? 'pointer'
                            : 'not-allowed',
                        background: '#F4F4F4',
                        width: '44px',
                        height: '44px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconBrandInstagram
                        color={
                          agentDetails?.instagram_link ? '#7E0DB4' : '#7D7D7D'
                        }
                        fontSize="small"
                      />
                    </Box>
                    <Box
                      onClick={() =>
                        handleLinkClick(
                          data?.linkedInPageLink || agentDetails?.linkedin_link
                        )
                      }
                      sx={{
                        cursor:
                          data?.linkedInPageLink || agentDetails?.linkedin_link
                            ? 'pointer'
                            : 'not-allowed',
                        background: '#F4F4F4',
                        width: '44px',
                        height: '44px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconBrandLinkedinFilled
                        color={
                          agentDetails?.linkedin_link ? '#7E0DB4' : '#7D7D7D'
                        }
                        fontSize="small"
                      />
                    </Box>
                    <Box
                      onClick={() =>
                        handleLinkClick(
                          data?.twitterPageLink || agentDetails?.twitter_link
                        )
                      }
                      sx={{
                        cursor:
                          data?.twitterPageLink || agentDetails?.twitter_link
                            ? 'pointer'
                            : 'not-allowed',
                        background: '#F4F4F4',
                        width: '44px',
                        height: '44px',
                        borderRadius: '8px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <YouTubeIcon
                        fontSize="small"
                        sx={{
                          color: agentDetails?.twitter_link
                            ? '#7E0DB4'
                            : '#7D7D7D',
                        }}
                      />
                    </Box>
                  </Box>
                </Grid>

                {/* testimonials */}
                <Box
                  className="box"
                  sx={{ border: '1px solid #F5EDFF', display: 'none' }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          color: '#474747',
                          fontSize: '1.5rem',
                          fontWeight: '500',
                        }}
                      >
                        Testimonial
                      </Typography>

                      {testimonial?.length < 1 && (
                        <Typography
                          sx={{
                            color: '#474747',
                            fontSize: '14px',
                            fontWeight: '400',
                            marginBottom: '1.5rem',
                          }}
                        >
                          Your clients feedback will be displayed here.
                        </Typography>
                      )}
                    </Box>
                  </Box>

                  <Box>
                    {testimonial?.length < 1 && (
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{
                          textTransform: 'capitalize',
                          borderRadius: '6px',
                        }}
                      >
                        Share Feedback Form
                      </Button>
                    )}
                  </Box>

                  <Grid container rowGap={2}>
                    {testimonial?.map((item) => (
                      <Grid
                        item
                        lg={12}
                        sx={{
                          background: '#F8F8F8',
                          padding: '.5rem 1rem',
                          borderRadius: '8px',
                          marginTop: '1rem',
                        }}
                      >
                        <Typography
                          sx={{
                            color: '#474747',
                            fontSize: '14px',
                            fontWeight: '400',
                          }}
                        >
                          "{item?.remark}"
                        </Typography>
                        <Box
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '1rem',
                            columnGap: 1.2,
                          }}
                        >
                          <Avatar sx={{ width: 24, height: 24 }}>A</Avatar>
                          <Typography
                            sx={{ color: '#600DB4', fontSize: '14px' }}
                          >
                            {item.name}
                          </Typography>
                          <Typography
                            sx={{ color: '#7D7D7D', fontSize: '12px' }}
                          >
                            <CircleIcon sx={{ fontSize: '6px' }} /> 4 days ago
                          </Typography>
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Grid>

              {/* Right side */}
              <Grid item container lg={6.8} sm={12}>
                <Box
                  sx={{
                    borderBottom: '0.5px solid #D2D2D2',
                    width: '100%',
                    marginBottom: '1rem',
                  }}
                >
                  <Tabs
                    value={selectedPropertyTab}
                    onChange={(e, newVal) => setSelectedPropertyTab(newVal)}
                    textColor="secondary"
                    indicatorColor="secondary"
                  >
                    <Tab
                      label={`${t(TRANSLATIONS.TITLES.ACTIVE_LISTING)} ${
                        (agentId && parsedPropertyIds?.length
                          ? recommendedAgentProperties?.active?.recommended
                              ?.length
                          : properties?.active?.length) || 0
                      }`}
                      {...a11yProps(0)}
                      sx={{ textTransform: 'initial' }}
                    />
                    <Tab
                      label={`${t(TRANSLATIONS.TITLES.CLOSED)} ${
                        (agentId
                          ? recommendedAgentProperties?.closed?.length
                          : properties?.closed?.length) || 0
                      }`}
                      {...a11yProps(1)}
                      sx={{ textTransform: 'initial' }}
                    />
                  </Tabs>
                </Box>
                {/* show in case of active recommendation properties  */}
                {agentId &&
                  parsedPropertyIds?.length > 0 &&
                  (recommendedAgentProperties?.active?.recommended?.length >
                    0 ||
                    recommendedAgentProperties?.active?.others?.length > 0 ||
                    recommendedAgentProperties?.closed?.length > 0) && (
                    <>
                      {selectedPropertyTab === 0 && (
                        <>
                          <PageTitle
                            description={`As you searched for ${
                              locality || city
                            }, here are ${
                              recommendedAgentProperties?.active?.recommended
                                ?.length || 0
                            } properties listed in this agent's profile!`}
                          />
                          {/* active recommended */}
                          {recommendedAgentProperties?.active?.recommended
                            ?.length > 0 && (
                            <Grid
                              container
                              gap={2}
                              sx={{
                                padding: '1rem',
                                borderRadius: '8px',
                                margin: '1rem 0 !important',
                                background: '#fff',
                              }}
                            >
                              {recommendedAgentProperties?.active?.recommended?.map(
                                (item) => (
                                  <PropertyCard
                                    item={item}
                                    customerId={customerId}
                                    handleSelectedProperty={
                                      handleSelectedProperty
                                    }
                                  />
                                )
                              )}
                            </Grid>
                          )}

                          {/* active others */}
                          {recommendedAgentProperties?.active?.others?.length >
                            0 && (
                            <Box sx={{ marginTop: '2rem', width: '100%' }}>
                              <Typography
                                sx={{ color: '#474747', fontSize: '16px' }}
                              >
                                {t(TRANSLATIONS.SUB_TITLES.OTHER_PROPERTIES)}
                              </Typography>
                              <Divider
                                sx={{
                                  width: '100%',
                                  color: '#D2D2D2',
                                  margin: '.5rem 0 2rem 0',
                                }}
                              />

                              <Grid
                                container
                                gap={2}
                                sx={{
                                  padding: '1rem',
                                  borderRadius: '8px',
                                  margin: '1rem 0 !important',
                                  background: '#fff',
                                }}
                              >
                                {recommendedAgentProperties?.active?.others?.map(
                                  (item) => (
                                    <PropertyCard
                                      item={item}
                                      customerId={customerId}
                                      handleSelectedProperty={
                                        handleSelectedProperty
                                      }
                                    />
                                  )
                                )}
                              </Grid>
                            </Box>
                          )}
                        </>
                      )}

                      {/* closed recommended */}
                      {selectedPropertyTab === 1 && (
                        <>
                          <Grid
                            container
                            gap={2}
                            sx={{
                              padding: '1rem',
                              borderRadius: '8px',
                              margin: '1rem 0 !important',
                              background: '#fff',
                            }}
                          >
                            {recommendedAgentProperties?.closed?.length ? (
                              recommendedAgentProperties?.closed?.map(
                                (item) => (
                                  <PropertyCard
                                    item={item}
                                    customerId={customerId}
                                    handleSelectedProperty={
                                      handleSelectedProperty
                                    }
                                  />
                                )
                              )
                            ) : (
                              <NoPropertiesFound
                                agentDetails={agentDetails}
                                loggedInAgentId={loggedInAgentId}
                                selectedPropertyTab={selectedPropertyTab}
                                customerId={customerId}
                              />
                            )}
                          </Grid>
                        </>
                      )}
                    </>
                  )}

                {/* agent properties - non recommended  */}
                {!parsedPropertyIds?.length && (
                  <Grid
                    item
                    lg={12}
                    sx={{
                      padding: '1rem',
                      borderRadius: '8px',
                      margin: '1rem 0 !important',
                      background: '#fff',
                    }}
                  >
                    {!properties?.[
                      selectedPropertyTab === 0 ? 'active' : 'closed'
                    ]?.length ? (
                      <NoPropertiesFound
                        agentDetails={agentDetails}
                        loggedInAgentId={loggedInAgentId}
                        selectedPropertyTab={selectedPropertyTab}
                        customerId={customerId}
                      />
                    ) : null}

                    <Grid container gap={2} justifyContent="flex-start">
                      {properties?.[
                        selectedPropertyTab === 0 ? 'active' : 'closed'
                      ]?.map((item) => (
                        <PropertyCard
                          item={item}
                          customerId={customerId}
                          handleSelectedProperty={handleSelectedProperty}
                        />
                      ))}
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>

            {openEditModal && (
              <UpdateProfile
                open={openEditModal}
                agentDetails={agentDetails}
                getAgentDetails={() => getLoggedInAgentDetails()}
                onClose={() => setOpenEditModal(false)}
                updateAgentProfilePicture={updateAgentProfilePicture}
              />
            )}
          </Box>
        </Box>

        <GenerateQRModal
          open={showGenerateQRModal}
          setOpen={setShowGenerateQRModal}
          agentDetails={agentDetails}
        />

        <PropertyDetail
          selectedPropertyID={selectedPropertyID}
          toggleDrawer={toggleDrawer}
          open={openPropertyDetail}
          setOpen={setOpenPropertyDetail}
          from="profile"
          externalAgentId={
            agentDetails?.customer_id === loggedInAgentId
              ? null
              : agentDetails?.customer_id
          }
        />
      </Box>
    </>
  );
};

const PropertyCard = ({ item, customerId, handleSelectedProperty }) => {
  const { t } = useTranslation();
  return (
    <Grid
      item
      lg={customerId ? 12 : 5.8}
      md={3.8}
      sm={12}
      xs={12}
      sx={{
        borderRadius: '10px',
        position: 'relative',
        cursor: 'pointer',
        width: '100%',
        border: '1px solid #EFEFEF',
        padding: '1rem',
      }}
      onClick={(e) => {
        console.log(item, 'item');
        handleSelectedProperty(item.property_id, e.target);
      }}
    >
      <Box
        sx={{
          height: '180px',
          width: '100%',
          borderRadius: '16px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: '#E7E3FC',
          position: 'relative',
        }}
      >
        <Typography sx={{ color: '#7D7D7D' }}>
          {t(TRANSLATIONS.SUB_TITLES.IMAGE_NOT_AVAILABLE)}
        </Typography>
        <img
          src={item.cover_picture}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '16px',
            objectFit: 'cover',
            position: 'absolute',
          }}
          alt=""
        />
        <Typography
          sx={{
            position: 'absolute',
            bottom: '12px',
            left: '10px',
            background: 'rgba(254, 254, 254, 0.8)',
            color: '#000',
            borderRadius: '8px',
            padding: '2px 8px',
            fontSize: '14px',
          }}
        >
          {item?.property_sale_type}
        </Typography>
      </Box>

      <Box sx={{ width: '100%' }}>
        <Typography
          sx={{
            color: '#474747',
            fontSize: '14px',
            fontWeight: '600',
            marginTop: '1rem',
          }}
        >
          {item.property_title}
        </Typography>

        <Typography
          sx={{
            margin: '.5rem 0',
            color: '#7D7D7D',
            fontSize: '14px',
          }}
        >
          {[
            item?.built_up_area ? item?.built_up_area + ' sq.ft.' : '',
            item?.bedroom_count ? item?.bedroom_count + 'BHK' : '',
            item?.furnish_type,
          ]
            .filter(Boolean)
            .join(' | ')}
        </Typography>

        <Box
          sx={{
            display: 'flex',
            alignItems: 'flex-start',
            marginTop: '8px',
            gap: '4px',
          }}
        >
          <IconMapPin size="18px" color="#7D7D7D" />
          <Typography
            sx={{
              color: '#7D7D7D',
              fontSize: '14px',
              fontWeight: '400',
            }}
          >
            {item?.locality ? item?.locality + ', ' : ''}
            {item?.city ? item?.city : ''}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          background: '#fff',
          position: 'absolute',
          top: '50%',
          right: '8%',
          padding: '4px .5rem ',
          borderRadius: '6px',
        }}
      >
        <Typography
          sx={{
            color: '#600DB4',
            fontSize: '14px',
            fontWeight: '500',
          }}
        >
          {numDifferentiation(item.sale_price || item.rent_amount || 0)}
        </Typography>
      </Box>
    </Grid>
  );
};

const NoPropertiesFound = ({
  agentDetails,
  loggedInAgentId,
  selectedPropertyTab,
  customerId,
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        width: '100%',
        textAlign: 'center',
        padding: '1rem 0',
      }}
    >
      <img
        style={{ height: '102px' }}
        src={ImagesLinks.noPropertyFound}
        alt="no property found"
      />
      <Typography
        sx={{
          color: '#A49D9D',
          fontWeight: '900',
          margin: '8px 0',
        }}
      >
        {selectedPropertyTab === 0
          ? t(TRANSLATIONS.SUB_TITLES.NO_PROPERTIES_YET)
          : t(TRANSLATIONS.SUB_TITLES.NO_CLOSED_PROPERTIES_YET)}
      </Typography>
      <Typography
        sx={{
          color: '#A49D9D',
          fontSize: '16px',
          fontWeight: '400',
          marginBottom: '1.5rem',
          padding: { lg: customerId ? 0 : '0 10rem', xs: '0' },
          lineHeight: 1.6,
        }}
      >
        {agentDetails?.customer_id === loggedInAgentId
          ? t(TRANSLATIONS.PARAGRAPHS.START_ADDING_PROPERTIES)
          : selectedPropertyTab === 0
          ? t(TRANSLATIONS.SUB_TITLES.NO_LISTING_AVAILABLE)
          : t(TRANSLATIONS.SUB_TITLES.NO_DEALS_CLOSED)}
      </Typography>

      {agentDetails?.customer_id === loggedInAgentId && (
        <Button
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'none',
            height: '40px',
            borderRadius: '8px',
            background:
              'linear-gradient(131.14deg, #7E0DB4 1.71%, #590DB4 100%)',
            padding: '10px 20px',
          }}
          onClick={() => navigate('/add-property')}
        >
          {t(TRANSLATIONS.SUB_TITLES.ADD_A_PROPERTY)}
        </Button>
      )}
    </Box>
  );
};

export default ViewProfile;
