import axios from 'axios';
import { LOCAL_STORAGE_DATA } from '../constants/app-constants';
import { fetchRefreshToken } from '../Utils/helperUtils';
import qs from 'qs';
var internals = {};

const API_URL = process.env.REACT_APP_API_BASE_URL;
console.log(API_URL, 'API_URL');

let isRefreshing = false;
let refreshSubscribers = [];

internals.requestServer = async function (
  endPoint,
  data,
  method = 'GET',
  headers = {},
  contentType = 'application/json',
  auth = false
) {
  let defaultHeaders = {
    Accept: 'application/json',
    'Content-Type': contentType,
  };

  if (auth) {
    defaultHeaders['Authorization'] = `Bearer ${localStorage.getItem(
      LOCAL_STORAGE_DATA.ACCESS_TOKEN
    )}`;
  }

  let params = {
    method,
    headers: { ...defaultHeaders, ...headers },
    url: `${API_URL}${endPoint}`,
  };

  if (data) {
    if (method === 'GET') {
      endPoint += (endPoint.includes('?') ? '&' : '?') + qs.stringify(data);
    } else {
      params.data = data;
    }
  }

  try {
    return await axios(params);
  } catch (error) {
    if (error.response?.status === 401) {
      console.warn('401 Unauthorized, attempting to refresh token...');

      return new Promise((resolve, reject) => {
        refreshSubscribers.push(() =>
          internals
            .requestServer(endPoint, data, method, headers, contentType, auth)
            .then(resolve)
            .catch(reject)
        );

        if (!isRefreshing) {
          isRefreshing = true;

          fetchRefreshToken()
            .then((newToken) => {
              if (newToken) {
                refreshSubscribers.forEach((retryRequest) => retryRequest()); // Call each stored API request
              } else {
                refreshSubscribers.forEach((subscriber) =>
                  subscriber.reject(error)
                );
              }

              refreshSubscribers = [];
              isRefreshing = false;
            })
            .catch((err) => {
              refreshSubscribers.forEach((subscriber) =>
                subscriber.reject(err)
              );
              refreshSubscribers = [];
              isRefreshing = false;
            });
        }
      });
    }
    return Promise.reject(error);
  }
};

export default internals.requestServer;
