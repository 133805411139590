import React, { useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './SignupLoginForm.css'; // Ensure you have this CSS file for custom styles
import INDIANFLAG from '../../Assets/images/indian-flag.png';
import { VERIFY_OTP } from '../../constants/route-path';
import apis from '../../Services/api-services';
import { useDispatch } from 'react-redux';
import { userActions } from '../../Store/userSlice';
import { ALERT_TYPES } from '../../constants/app-constants';
import { RotatingLines } from 'react-loader-spinner';
import LoginWrapperNew from '../../components/loginWrapperNew';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../../Utils/translations';

const SignupLoginForm = (props) => {
  const { t } = useTranslation();
  const [mobile, setMobile] = useState('');
  const [errors, setErrors] = useState({});
  const [openSnackbar, setOpenSnackbar] = useState(false); // State to control error Snackbar
  const [alertMsg, setAlertmsg] = useState('');
  const [alertType, setAlertType] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const regexOnlyNumber = /^[0-9]*$/;
  const wrapperProps = {
    openSnackbar,
    setOpenSnackbar,
    setAlertmsg,
    alertType,
    alertMsg,
  };

  const validateForm = () => {
    const newErrors = {};
    if (!mobile) {
      newErrors.mobile = 'Mobile number is required';
    } else if (!/^\d{10}$/.test(mobile)) {
      newErrors.mobile = 'Enter a valid 10-digit Indian mobile number';
    }
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    if (!validateForm()) return;
    const payload = { mobile, channel: 'android' };
    setLoading(true);
    apis.user.sendOtp(payload).then((response) => {
      setLoading(false);
      if (response.status === 200) {
        dispatch(userActions.setUserDetails({ mobile }));

        navigate(VERIFY_OTP);
      } else {
        setAlertmsg('Login Failed!');
        setAlertType(ALERT_TYPES.error);
        setOpenSnackbar(true);
        console.error('Signup failed:', response);
      }
    });
  };

  const handleInputChange = (value, id) => {
    let newErrors = {};
    switch (id) {
      case 'mobile':
        if (regexOnlyNumber.test(value) && value.length < 11) {
          setMobile(value);
        }
        break;
      default:
        break;
    }
    setErrors(newErrors);
  };

  return (
    <LoginWrapperNew {...wrapperProps} firstPage={true}>
      <div className="signup-login-form-content">
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '90%',
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <Typography
            sx={{
              color: '#474747',
              fontWeight: 400,
              fontSize: '16px',
              py: '10px',
              pb: '5px',
            }}
          >
            {t(TRANSLATIONS.TITLES.WELCOME_TO)}{' '}
            <span style={{ fontWeight: 600 }}>vREfyd</span>
          </Typography>
          <Typography
            sx={{
              fontWeight: 600,
              color: '#474747',
              fontSize: '22px',
              pt: '12px',
              pb: '30px',
            }}
          >
            {t(TRANSLATIONS.SUB_TITLES.LETS_BEGIN_WITH_PHONE_NUMBER)}
          </Typography>
          <div
            className={`input-container ${errors.mobile ? 'input-error' : ''}`}
          >
            <img src={INDIANFLAG} alt="India Flag" className="flag-icon" />
            <span className="country-code">+91</span>
            <input
              type="number"
              placeholder="9999999999"
              id="mobile"
              value={mobile}
              disabled={loading}
              onChange={(e) => handleInputChange(e.target.value, e.target.id)}
              className={`signup-inputs `}
              autocomplete="off"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSubmit();
                }
              }}
            />
          </div>
          <span className="signup-error-msg">{errors.mobile}</span>
          <Button
            type="submit"
            variant="contained"
            className="signup-btn"
            color="primary"
            onClick={() => {
              if (!loading) {
                handleSubmit();
              }
            }}
            sx={{ textTransform: 'capitalize', gap: '4px' }}
            fullWidth
          >
            {loading
              ? t(TRANSLATIONS.TITLES.SUBMITTING)
              : t(TRANSLATIONS.TITLES.SUBMIT)}

            <RotatingLines
              visible={loading}
              height="24"
              width="24"
              strokeColor="#fff"
              strokeWidth="5"
              animationDuration="0.7"
            />
          </Button>
        </Box>
      </div>
    </LoginWrapperNew>
  );
};

export default SignupLoginForm;
