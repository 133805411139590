import { Helmet } from 'react-helmet-async';

const Meta = ({ title, description, keywords, ogImage, favIcon }) => {
  return (
    <Helmet>
      {title && (
        <>
          <title>{title}</title>
          <meta property="og:title" content={title} />
          <meta name="twitter:title" content={title} />
        </>
      )}

      {description && (
        <>
          <meta name="description" content={description} />
          <meta property="og:description" content={description} />
          <meta name="twitter:description" content={description} />
        </>
      )}

      {keywords && <meta name="keywords" content={keywords} />}
      {ogImage && <meta property="og:image" content={ogImage} />}
      {favIcon && <link rel="icon" href={favIcon} />}
      {favIcon && <link rel="apple-touch-icon" href={favIcon} />}
    </Helmet>
  );
};

export default Meta;
