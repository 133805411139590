export const TRANSLATIONS = {
  // titles
  TITLES: {
    HOME: 'Home',
    PROPERTIES: 'Properties',
    MESSAGES: 'Messages',
    AGENT_ID: 'Agent Id',
    LOGOUT: 'Logout',
    PROFILE: 'Profile',
    ASK_AIRA: 'Ask AIRA...',
    SAVED: 'Saved',
    UNLOCKED: 'Unlocked',
    MY_LISTINGS: 'My Listings',
    LANGUAGE: 'Language',
    SUBMIT: 'Submit',
    SUBMITTING: 'Submitting...',
    CHANGE: 'Change',
    BACK: 'Back',
    DASHBOARD: 'Dashboard',
    SAVE: 'Save',
    CANCEL: 'Cancel',
    SHARE_FEEDBACK: 'Share Feedback',
    CITY: 'City',
    LOCALITY: 'Locality',
    LOOKING_FOR: 'Looking for',
    PROPERTY_TYPE: 'Property type',
    BEDROOM: 'Bedroom',
    BEDROOMS: 'Bedrooms',
    FILTERS: 'Filters',
    FURNISHING: 'Furnishing',
    BUILD_UP_AREA: 'Built up area (in sq.ft.)',
    BUDGET: 'Budget',
    CLEAR_ALL: 'Clear All',
    APPLY: 'Apply',
    MIN_BUILD_UP: 'Min Build Up',
    MAX_BUILD_UP: 'Min Build Up',
    FILTER: 'filter',
    AMENITIES: 'Amenities',
    NOTIFICATIONS: 'Notifications',
    BATHROOMS: 'Bathrooms',
    MESSAGE: 'Message',
    NAME: 'Name',
    PHONE_NUMBER: 'Phone Number',
    EMAIL: 'Email',
    LANGUAGE_S: 'Language(s)',
    REMOVE: 'Remove',
    ADDRESS: 'Address',
    AGENT: 'Agent',
    AREA: 'Area',
    VERIFY: 'Verify',
    PROCEED: 'Proceed',
    STATE: 'State',
    PINCODE: 'Pincode',
    SELECT: 'Select',
    RERA_NUMBER: 'RERA Number',
    ADD_MORE_DETAILS: 'Add more details',
    RETAKE_REUPLOAD_PHOTO: 'Retake / Reupload Photo',
    CLOSED_DEALS: 'Closed Deals',
    ACTIVE_LISTING: 'Active Listing',
    VERIFICATION: 'Verification',
    ABOUT: 'About',
    CLOSED: 'Closed',
    AVAILABILITY: 'Availability',
    ACCESS: 'ACCESS',
    TRY_AGAIN: 'Try Again',
    LATITUDE: 'Latitude',
    LONGITUDE: 'Longitude',
    RE_VERIFY: 'Re-Verify',
    HIDE: 'hide',
    VIEW_ALL: 'view all',
    FIND_PROPERTY: 'Find Property',
    TAP_THE: 'Tap the',
    WE_FOUND: 'We found',
    MATCHES_INSTANTLY: 'Matches Instantly',
    WE_ARE_ON_IT: 'We’re on it!',
    SELECT_CITY: 'Select City',
    TYPE: 'Type',
    FIND: 'Find',
    BALCONIES: 'Balconies',
    PROPERTY_CATEGORY: 'Property Category',
    OCCUPANCY_STATUS: 'Occupancy Status',
    CARPET_AREA: 'Carpet Area (in sq.ft.)',
    TOTAL_FLOOR: 'Total Floor',
    PROPERTY_ON_FLOOR: 'Property On Floor',
    PROPERTY_FACING: 'Property Facing',
    BUILT_IN_YEAR: 'Built in Year',
    PROPERTY_NAME: 'Property Name',
    LIFT: 'Lift',
    CAR_PARKING: 'Car Parking',
    FURNISHING_TYPE: 'Furnishing Type',
    PROPERTY_VALUE: 'Property Value',
    LOCK_IN_PERIOD: 'Lock In Period',
    RENT_AMOUNT: 'Rent Amount',
    SECURITY_DEPOSIT: 'Security Deposit',
    MAINTENANCE_AMOUNT: 'Maintenance Amount',
    RENT_AVAILABLE_FROM: 'Rent Available From',
    PROPERTY_ADDED_ON: 'Property Added On',
    TENANT_PREFERENCES: 'Tenant Preferences',
    UNSAVE: 'Unsave',
    CLOSED_WITH: 'Closed with',
    CHAT_UNLOCKED: 'Chat Unlocked!',
    BALCONY: 'Balcony',
    INTERESTING_HIGHLIGHTS: 'Interesting Highlights',
    ESSENTIAL_INFORMATION: 'Essential Information',
    DOCUMENT: 'Document',
    UNLOCK_TO_CHAT: 'Unlock to Chat',
    PROPERTY_REJECTED: 'Property Rejected',
    VERIFY_NOW: 'Verify Now',
    RE_VERIFY_NOW: 'Re-Verify Now',
    MARK_AS_CLOSED: 'Mark as Closed',
    PREVIEW_AND_PERFECT: 'Preview and Perfect',
    EDIT_PROFILE: 'Edit Profile',
    SHARE_PROFILE: 'Share Profile',
    RE_INITIATE_VERIFICATION: 'Re-Initiate Verification',
    VERIFICATION_IN_PROGRESS: 'Verification in progress',
    VERIFICATION_REJECTED: 'Verification Rejected',
    ADDITIONAL_SERVICES: 'Additional Services',
    GENERATE: 'Generate',
    ADD_CITY: 'Add City',
    LISTING_TYPE: 'Listing Type',
    COMMERCIAL_PARKING: 'Commercial Parking',
    ADD_AMENITY: 'Add Amenity',
    ADD: 'Add',
    SKIP: 'Skip',
    NEXT: 'Next',
    MY_LEADS: 'My Leads',
    DONE: 'Done',
    WELCOME_TO: 'Welcome to',
    VIEW_PROFILE: 'View profile',
    DEALS_CLOSED: 'Deals closed',
    YEARS: 'Years',
    FRESHER: 'Fresher',
    SPECIALIZATION: 'Specialization',
    AGENTS: 'Agents',
    TOP: 'Top',
    SHARE_NOW: 'Share now',
    SEARCH: 'Search',
    BUY_PURCHASE: 'Buy/Purchase',
    VERIFYING_OTP: 'Verifying OTP',
    RESEND_CODE: 'Resend Code',
    CONGRATULATIONS: 'Congratulations',
    SEARCH_MESSAGES: 'Search messages',
    UPLOAD_DOC: 'Upload doc',
    BROKERAGE: 'Brokerage',
    UNLOCKS: 'Unlocks',
    LOCATION: 'Location',
    INFO: 'Info',
    COMPANY: 'Company',
    DESCRIPTION: 'Description',
    SAVING_CHANGES: 'Saving Changes...',
    REGENERATE: 'Regenerate',
    REGENERATING_DESCRIPTION: 'Regenerating description...',
    ENTER_DESCRIPTION: 'Enter Description',
    VERIFYING: 'Verifying...',
    POSTED_BY: 'Posted by:',
    LOADING: 'Loading...',
    SELECT_PROPERTY: 'Select Property',
    ALL: 'All',
    PROPERTY: 'Property',
    SHARE_CARD: 'Share Card',
    DOWNLOAD: 'Download',
    YEARS_OF_EXPERIENCE: 'Years of Experience',
    OTHER_SERVICES: 'Other Services',
  },

  // sub titles
  SUB_TITLES: {
    PROPERTY_VERIFICATION: 'Property Verification',
    SELECTED_COORDINATES: 'Selected Coordinates',
    LOCATION_PIN_REQUIRED: 'Location Pin Required',
    PAGE_NOT_FOUND: 'Page not Found',
    FIND_CONNECT_CLOSE_FASTER: 'Find, Connect, Close Faster.',
    PASTE_PROPERTY_DETAILS_HERE: 'Paste your property details here',
    AUTO_FILL_WITH: 'Autofill with',
    FILTER_S_ADDED: 'filter(s) added',
    AREAS_OF_OPERATION: 'Areas of Operation',
    LEGAL_BUSINESS_NAME: 'Legal Business Name',
    BRAND_NAME: 'Brand Name',
    GST_NUMBER: 'GST Number',
    SOCIAL_LINKS: 'Social Links',
    INSTAGRAM_PAGE_LINK: 'Instagram Page Link',
    FACEBOOK_PAGE_LINK: 'Facebook Page Link',
    LINKEDIN_PAGE_LINK: 'LinkedIn Page Link',
    YOUTUBE_PAGE_LINK: 'Youtube Page Link',
    PHONE_NUMBER_OF_THE_AGENT: 'Phone Number of the Agent',
    ADD_A_PROPERTY: 'Add a Property',
    CONNECT_SMARTER: 'Connect Smarter,',
    CLOSE_FASTER: 'Close Faster with vREfyd.',
    AIRA_FIND: 'FIND what you are looking for...',
    NO_PROPERTY: 'You have not added any property yet',
    NOT_JUST_PART_OF_SYSTEM: 'You’re Not Just a Part of the System.',
    YOU_ARE_THE_SYSTEM: 'You Are The System!',
    JOIN_COMMUNITY: 'Join India’s most trusted real estate community',
    WELCOME_TO_VREFYD: 'Welcome to vREfyd',
    LETS_BEGIN: "Let's begin with your phone number",
    OTP_NOT_RECEIVED: 'Didn’t receive the OTP? Resend in',
    ENTER_VERIFICATION_CODE: 'Enter the verification code sent to your phone',
    PLEASE_CHECK_WHATSAPP_OR_SMS:
      'Please check WhatsApp/SMS for verification code',
    LIST_MY_PROPERTY: 'List my Property',
    CHOOSE_LANGUAGE: 'Choose Language',
    LOADING_NOTIFICATIONS: 'Loading Notifications....',
    NO_NOTIFICATION_FOUND: 'No notification found',
    BY_SUBMITTING: 'By submitting you agree to our ',
    PRIVACY_POLICY: 'privacy policy',
    AND: 'and',
    TERMS_OF_USE: ' terms of use',
    LETS_GET_TO_KNOW_YOU: 'LET’S GET TO KNOW YOU',
    YEARS_OF_EXPERIENCE: 'Years of Experience',
    HIGHLIGHT_YOUR_SUPERPOWERS: 'Highlight Your Superpowers',
    MY_SPECIALIZATION: 'My Specialization',
    OTHER_SERVICES: 'Other Services',
    SPEAK_THEIR_LANGUAGE: 'Speak Their Language',
    DEFINE_YOUR_AREA_OF_OPERATION: 'Define your Area of Operation',
    ADD_ANOTHER_CITY: 'Add another city',
    SHOWCASE_YOUR_BRAND: 'Showcase Your Brand',
    BUSINESS_DETAILS: 'Business Details',
    IMAGE_OF_VISITING_CARD: 'Image of Visiting Card',
    IMAGE_OF_SHOP_ESTABLISHMENT: 'Image of Shop / Establishment',
    COMPANY_PAN_CARD_GST_CERTIFICATE: 'Company Pan Card / GST Certificate',
    VERIFIED_AGENT_VERIFICATION: 'Verified Agent Verification',
    FASTEST_IMMEDIATE_VERIFICATION: 'Fastest - Immediate Verification',
    BUSINESS_VERIFICATION: 'Business Verification',
    RERA_VERIFICATION: 'RERA Verification',
    COMMUNITY_VERIFICATION: 'Community Verification',
    UPLOAD_DOCUMENTS: 'Upload Documents',
    LETS_CAPTURE_YOUR_BEST_LOOK: 'Let’s Capture Your Best Look',
    KICK_THINGS_OFF:
      'Kick things off with a great photo! Upload a professional picture that makes you look approachable and memorable.',
    POSITION_YOUR_FACE_IN_THE_SQUARE_BELOW:
      'Position your face in the square below',
    SAMPLE_PROFILE_PREVIEW: 'Sample Profile Preview',
    SAMPLE_PROFILE_APPEARS: 'Sample Profile – This is how your profile appears',
    BACK_TO_HOME: 'Back to Home',
    DEAL_CLOSED_OUTSIDE: 'The deal was closed outside',
    VERIFICATION_INITIATED: 'Verification Initiated!',
    PROPERTY_VERIFICATION_FAILED: 'Property Verification Failed',
    DUPLICATE_LISTING_DETECTED: 'Duplicate Listing Detected',
    IMAGE_NOT_AVAILABLE: 'Image not available',
    SAVE_FAV_PROP: 'Save your favourite properties',
    TAP_TO_SAVE: 'on any property to add it to your saved properties',
    PERSONALIZE_SEARCH: 'To personalize your search apply more filters',
    SEARCH_FOR_LOCALITY: 'Search for Locality',
    PURCHASE_RENT: 'Purchase/Rent',
    ADVANCE_FILTERS: 'Advance Filters',
    PROPERTY_SALE_TYPE: 'Property Sale Type',
    CHAT_TO_KNOW_MORE: 'Chat to know more about the property',
    HOUSE_FLAT_BLOCK_NUMBER: 'House / Flat / Block Number',
    APARTMENT_SOCIETY: 'Apartment / Society',
    ROAD_AREA: 'ROAD / AREA',
    BEDROOMS_ARE_REQUIRED: 'Bedrooms are required',
    BALCONIES_ARE_REQUIRED: 'Balconies are required',
    BATHROOMS_ARE_REQUIRED: 'Bathrooms are required',
    LISTING_TYPE_IS_REQUIRED: 'Listing type is required',
    PROPERTY_CATEGORY_IS_REQUIRED: 'Property category is required',
    PROPERTY_TYPE_IS_REQUIRED: 'Property type is required',
    FURNISHING_TYPE_IS_REQUIRED: 'Furnishing type is required',
    OCCUPANCY_STATUS_IS_REQUIRED: 'Occupancy status is required',
    BUILD_UP_AREA_IS_REQUIRED: 'Build up area is required',
    CARPET_AREA_IS_REQUIRED: 'Carpet area is required',
    NO_PROPERTIES_YET: 'No Properties Yet',
    NO_CLOSED_PROPERTIES_YET: 'No Closed Properties Yet',
    NO_LISTING_AVAILABLE: 'No listings available at the moment.',
    NO_DEALS_CLOSED: 'No deals closed yet. Stay tuned!',
    PERSONAL_DETAILS: 'Personal Details',
    WELCOME_GLAD_TO_HAVE_YOU_ABOARD: 'Welcome! Glad to have you aboard.',
    TELL_US_YOUR_NAME: 'Tell us your name',
    YOUR_PREFERRED_EMAIL_ADDRESS: 'Your preferred email address',
    TRANSACTIONAL_MESSAGES_WHATSAPP:
      'Transactional messages and updates will be sent to your WhatsApp',
    LETS_BEGIN_WITH_PHONE_NUMBER: "Let's begin with your phone number",
    NO_CONVERSATIONS_AVAILABLE: 'No conversations available.',
    NO_SPECIALIZATIONS_ADDED: 'No Specializations added',
    MATCHING_LISTINGS: 'Matching Listings',
    ENTER_YRS_OF_EXP: 'Enter the years of experience',
    START_BUILDING: 'Start Building',
    RETRY_VERIFICATION: "'Retry Verification'",
    PROFILE_VERIFICATION_REJECTED: 'Profile Verification Rejected',
    JOIN_THE_COMMUNITY_ALONG: 'Join the community along with',
    VREFYD_AGENTS: 'vREfyd agents',
    DIRECT_BUYER_TENANT_LEADS: 'Direct Buyer/Tenant Leads',
    STAGING_AND_VISUALIZATION: 'Staging & Visualization',
    FASTER_AGREEMENTS: 'Faster Agreements',
    EASY_FINANCING: 'Easy Financing',
    DIRECT_OWNER_AND_BUILDER_PROPERTIES: 'Direct Owner & Builder Properties',
    VERIFY_AS_AN_AGENT: 'Verify as an Agent',
    BOOST_YOUR_BROKERAGE: 'Boost Your Brokerage with Real Advantages',
    UNLIMITED_LISTINGS: 'Unlimited Listings',
    GROW_YOUR_BUSINESS: 'Grow Your Business',
    COMPLETE_DATA_SECURITY: 'Complete Data Security',
    KEEP_ALL_EARNINGS: 'Keep All Earnings',
    OUR_PROPOSITION: 'Our Proposition',
    EDGE_FOR_AGENTS: 'Edge for Agents',
    WIDER_REACH: 'Wider Reach',
    BETTER_TARGETING: 'Better Targeting',
    STREAMLINED_PROCESS: 'Streamlined Process',
    NEW_WAYS_TO_EARN: 'New Ways to Earn',
    COMING_SOON: 'Coming Soon!',
    I_AM_NOT_AN_AGENT: 'I am not an Agent',
    OTHER_PROPERTIES: 'Other Properties',
    SCAN_TO_CONNECT_WITH_ME: 'Connect and explore my unique work on vREfyd.',
    QR_LINK_COPIED: 'Profile QR link copied to clipboard',
  },

  // paragraphs
  PARAGRAPHS: {
    SERVICEABLE_IN_BENGALURU:
      'We’re currently serviceable in Bengaluru and will be expanding to more cities soon!',
    AIRA_INTRO:
      "I'm AIRA, India's first AI real estate agent. I find matches, write property descriptions, and share tips to make your job easier.",
    ADD_OTHER_DETAILS_FOR_SETUP:
      'I’ve got your name and phone number. Just add other details, and I’ll set up your profile in a snap!',
    QUICK_AND_EASY_SETUP:
      'Quick and easy setup saves you time to focus on growing your business.',
    SHARE_YOUR_TOP_SKILLS:
      'Share your top skills and strengths. I’ll showcase them to show why you’re the best in real estate.',
    ATTRACT_MORE_LEADS:
      'Attract more leads by clearly presenting what makes you unique.',
    TELL_ME_LANGUAGE:
      'Tell me which languages you speak fluently. It helps you reach a broader audience.',
    EXPAND_YOUR_REACH:
      'Expand your reach beyond local areas and connect with diverse clients.',
    SELECT_THE_CITIES:
      'Select the cities and areas you operate in. This helps people find you where it matters most.',
    TARGET_LOCAL_MARKET:
      'Target your local market effectively and increase your business opportunities.',
    AVAILABLE_IN_FIVE_CITIES:
      "We're currently available in these five cities and look forward to expanding soon",
    ADD_BUSINESS_AND_SOCIAL_MEDIA_DETAILS:
      'Add your business details and social media links. I’ll integrate them to boost your professional image.',
    ENCHANE_ONLINE_PRESENCE:
      'Enhance your online presence and save money on branding efforts.',
    RERA_VERIFICATION_NOTE:
      'At vREfyd, we prioritize your privacy by securely managing all documents and safeguarding your data throughout the RERA verification process',
    DOCUMENT_VERIFICATION_NOTE:
      'At vREfyd, your privacy is our priority. We ensure all your documents are securely managed, safeguarding your data at every step.',
    VREFYD_AGENT_DETAILS: 'Please provide details of a vREfyd Agent',
    COMMUNITY_DETAILS: 'Please provide details of your Community',
    PROVIDE_RERA_DETAILS: 'Please provide your RERA details',
    TWO_AGENT_REFERENCE:
      'Please provide references of two Agents who can help us verify that you are an Agent',
    KICK_THINGS_OFF:
      'Kick things off with a great photo! Upload a professional picture that makes you look approachable and memorable.',
    INSPIRATION_EXAMPLE:
      'Stand out instantly and attract more interest—no extra marketing needed! See the example below for inspiration.',
    PROFILE_IMAGE_APPEARANCE:
      'This is how your profile image appears. Upload/Click a quality picture for the best impression',
    ENCRYPT_DATA:
      'The data you share will be encrypted, stored securely, and only used to verify your identity',
    PROFILE_SHINE:
      'Let’s get started and make your profile shine! Check out the example below for inspiration.',
    START_ADDING_PROPERTIES:
      'Start adding properties now to showcase your listings to potential clients.',
    EYE_CATECHING_PROFILE_NOTE:
      'With my help, you’ll create a professional and eye-catching profile that attracts more leads, saves you money, and helps your real estate business thrive.',
    SIMPLY_TYPE_PROP_DETAILS_FOR_AUTOFILL:
      'Simply type or share details from your WhatsApp messages, and we will autofill your information effortlessly.',
    PROPERTIES_TRUSTED_COMMUNITY:
      'Properties, Trusted Community, and AI Tools for Quick, Secure Deals.',
    BETA_VERSION_NOTE: "You're currently using an internal Beta version",
    HEAVY_LOAD_NOTE:
      "We're facing some heavy load right now and feeling the pressure. Let’s wait a bit, and then you can try again",
    CANNOT_FIND_PAGE: "Sorry, we cant find the page you're looking for",
    DROP_THE_MAP_PIN: 'Please drop the pin on the map to confirm your location',
    DEAL_CLOSED_SUCCESSFULLY: 'Deal closed successfully',
    NEED_TO_CONFIRM_THE_DEAL_CLOSED_WITH: 'Deal closed successfully',
    USER_UNLOCKED_PROPERTY: 'Deal closed successfully',
    SIT_BACK_AND_RELAX:
      'Sit back and relax, We will let you know once the property is verified',
    PROPERTY_ALREADY_LISTED:
      'It looks like this property has already been listed. Please check for existing listings before submitting again.',
    PROPERTY_VERIFICATION_EXPIRES_IN: 'Property Verification Expires in',
    PROPERTY_CANNOT_VERIFIED:
      "We're sorry, your property couldn't be verified.",
    NO_UNLOCK_PROPS_YET:
      'No unlocked properties yet. Time to explore more leads!',
    NO_PERFECT_MATCH_YET:
      "No perfect match yet, but don't worry we've alerted our entire network!",
    FIRST_TO_KNOW:
      'The moment something ideal pops up, you’ll be the first to know.',
    FAILURE_TO_GUIDELINES:
      '*Failure to follow these guidelines will result in failed  verification.',
    COMPLETE_YOUR_PROFILE_FOR_PROP_VER:
      'Please complete your profile verification to start the property verification process.',
    VERIFICATION_PENDING_NOTE:
      "Verification is pending. We'll notify you about the status.",
    VERIFICATION_IN_PROGRESS_NOTE:
      'Your profile verification is in progress, We will let you know once the profile is verified.',
    QUICK_LOOK_PROFILE:
      'Take a quick look at your profile before it goes live. Let me know if you want any tweaks',
    ENSURE_PROFILE_PERFECTION:
      'Ensure your profile looks perfect, saving you from future adjustments.',
    PREFILLED_DETAILS_AIRA:
      'I’ve pre-filled the details for you based on the information you shared earlier. Take a quick look and fill in any fields that are left out!',
    PROPERTIES_YOU_RE_INTERESTED_IN:
      "Properties you're interested in are shown here.",
    CHAT_WITH_SOMEONE: 'It’s nice to chat with someone',
    PICK_A_PERSON: 'Pick a person from left menu and start your conversation',
    LEAD_INTERESTED: 'Leads interested in your property are shown here.',
    FOUND_A_PROP_MATCH:
      'I’ve found a property that matches what you’re looking for. Here is the top agent who has listed properties that meet your search criteria',
    COULD_NOT_FIND_ANY_PROP: `I couldn’t find any property that match your search right now, but don’t worry! I’ve already alerted our agent network, and I will notify you immediately when something comes up..`,
    FOUND_SEVERAL_PROP_MATCH:
      'I’ve found several properties that match what you’re looking for. Here are the',
    WHO_HAVE_LISTED: 'who have listed properties that match your search..',
    SHARE_YOUR_PREFERRED_LOCALITY:
      'Share your preferred locality, budget, or any other details below, and I’ll connect you with the perfect agent in no time!',
    LOOKING_FOR_SOMETHING_SPECIFIC: 'Looking for something more specific?',
    BUT_WE_HAVE_ALERTED_OUR_NETWORK:
      'But we’ve alerted our network. You’ll be notified when the right property comes up!',
    PROFILE_PREVIEW_AIRA_TITLE:
      'Sample Profile – This is how your profile appears',
    PROFILE_PREVIEW_AIRA_DESCRIPTION:
      'With my help, you’ll create a professional and eye-catching profile that attracts more leads, saves you money, and helps your real estate business thrive.',
    PROFILE_PREVIEW_AIRA_NOTE:
      'Let’s get started and make your profile shine! Check out the example below for inspiration.',
    CHECK_VERIFICATION_CODE: 'Please check WhatsApp/SMS for verification code',
    LOGIN_SUCCESSFUL: 'Your Login is Successful',
    TELL_ME_ABOUT_PROPERTY_INVESTMENT: 'Tell me about property investment',
    NEED_ADVICE_ON_BUYING: 'I need advice on buying a house',
    HOW_CAN_I_EARN_MORE_RENTAL_INCOME: 'How can I earn more rental income?',
    WHAT_ARE_THE_BEST_AREAS:
      'What are the best areas to invest in real estate?',
    BETA_VERSION_FEEDBACK_NOTE:
      "You're using a Beta version. Help improve AIRA by using Thumbs-Up or Thumbs-Down on responses.",
    HANG_TIGHT:
      'Hang tight! I’m setting up everything for your property listing',
    ANALYSING_YOUR_INPUTS:
      'Analysing your inputs to generate the perfect property description',
    OPTIMIZING_LISTING:
      'Optimizing your listing to attract the right buyers and renters',
    HIGHLIGHTING_FEATURES:
      'Highlighting key features to make your property stand out',
    ALMOST_VERIFYING_DETAILS:
      'Almost there! Verifying your details for a polished final touch',
    AT_LEAST_ONE_FILE_REQUIRED: 'At least one file is required',
    ENTER_VALID_PINCODE: 'Enter a valid pincode',
    PINCODE_REQUIRED: 'Pincode is required',
    PIN_LOCATION: 'Pin location on Map',
    ROAD_AREA_REQUIRED: 'Road or area is required',
    LOCALITY_REQUIRED: 'Locality is required',
    CITY_REQUIRED: 'City is required',
    STATE_REQUIRED: 'State is required',
    APARTMENT_REQUIRED: 'Apartment or Society name is required',
    HOUSE_NO_REQUIRED: 'House number is required',
    BUILT_UP_AREA_MUST_BE_A_NUMBER: 'Built-up area must be a number',
    ENTER_VALID_BUILT_UP_AREA: "Enter valid built-up area'",
    CARPET_AREA_MUST_BE_A_NUMBER: 'Carpet area must be a number',
    CARPET_AREA_MUST_BE_SMALLER_THAN_BUILT_UP_AREA:
      'Carpet area must be smaller than built-up area',
    ENTER_VALID_CARPET_AREA: 'Enter valid carpet area',
    ENTER_VALID_INPUT: 'Enter valid input',
    TOTAL_FLOORS_ARE_REQUIRED: 'Total floors are required',
    ENTER_VALID_NUMBER_OF_FLOORS: 'Enter valid number of floors',
    PROPERTY_ON_FLOOR_REQUIRED: 'Property on floor is required',
    PROPERTY_ON_FLOOR_MUST_BE_LESSER_THAN_TOTAL_FLOORS:
      'Property on floor must be lesser than total floors',
    LOCK_IN_PERIOD_IS_REQUIRED: 'Lock-in period is required',
    MAINTENANCE_AMOUNT_REQUIRED:
      'Maintenance amount is required for Rent listings',
    ENTER_VALID_MAINTENANCE_AMOUNT: 'Please enter a valid maintenance amount',
    SECURITY_DEPOSIT_REQUIRED: 'Security deposit is required for Rent listings',
    ENTER_VALID_SECURITY_DEPOSIT_AMOUNT:
      'Please enter a valid security deposit amount',
    RENT_AMOUNT_REQUIRED: 'Rent amount is required for Rent listings',
    ENTER_VALID_RENT_AMOUNT: 'Please enter a valid rent amount',
    SALE_PRICE_REQUIRED: "Sale price is required for 'For Sale' listings",
    PROPERTY_FACING_REQUIRED: 'Property facing is required',
    BROKERAGE_REQUIRED: 'Brokerage is required',
    TOTAL_FLOORS_REQUIRED: 'Total floors are required',
    NUMBER_ASSOCIATED_WITH_AGENT:
      'Success! This number is associated with a vREfyd agent',
    NUMBER_NOT_ASSOCIATED_WITH_AGENT:
      'Sorry, we couldn’t verify this phone number. Ensure it’s correct or try again later.',
    GAIN_TRUST_AND_CREDIBILITY:
      'Gain trust and credibility, making it easier to attract more leads.',
    THIS_IS_THE_FINAL_STEP:
      'This is the final step! Choose a verification method, get your purple tick, and start growing your business!',
    GET_VERIFIED_WITH_EASE: 'Get Verified with Ease',
    CHATTING_WITH_UNVERIFIED_AGENT:
      "The person you're chatting with may be an unverified agent or client. Please proceed with caution and ensure secure communication.",
    SHOWCASE_YOUR_EXPERTISE:
      'Showcase your expertise and stand out as a vREfyd agent!',
    YOUR_VERIFICATION_IS_PENDING: `Your profile verification is pending. We'll notify you when there's an update.`,
    YOUR_VERIFICATION_WAS_NOT_APPROVED:
      'Your profile verification was not approved. Kindly update the required details and resubmit to get verified.',
    BUILD_YOUR_OWN_DIGITAL_SHOP: 'Build Your own Digital Shop',
    DIRECT_TENANT_BUYER_LEADS:
      'Direct tenant, buyer leads: close deals smoothly',
    SHOW_PROPERTY_POTENTIAL:
      'Show property potential, boost closure success rate.',
    STREAMLINE_DEALS_WITH_QUICK:
      'Streamline deals with quick, hassle-free agreement execution.',
    OFFER_LOANS_MORTGAGES: 'Offer loans, mortgages, deposits with a click',
    EXCLUSIVE_OWNER_BUILDER_LISTINGS:
      'Exclusive owner, builder listings: close deals quickly',
    ONLY_VERIFIED_REALTORS_CAN_ADD:
      'Only verified realtors can add properties to ',
    COMPLETE_THE_VERIFICATION_PROCESS:
      'Complete the verification process to ensure genuine listings and maintain trust.',
    LIST_UNLIMITED_PROPS: 'List unlimited properties at no extra cost',
    CLOSE_MORE_DEALS: 'Close more deals with less effort',
    CONTROL_YOUR_DATA: 'Control your data, always secure and private',
    KEEP_EVERY_RUPEE: 'Keep every rupee of your brokerage earnings',
    REACH_BEYOND_YOUR_CITY: 'Reach beyond your city for more buyers',
    MATCH_YOUR_LISTINGS: 'Match your listings to the right audience',
    SKIP_MESSY_GROUPS:
      'Skip messy groups — manage all connections in one place.',
    PROFILE_PICTURE_UPDATED_SUCCESSFULLY:
      'Profile picture updated successfully',
  },
};
