import { Avatar, Box, Button, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import apis from '../../Services/api-services';
import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { numDifferentiation } from '../../Utils/helperUtils';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import html2canvas from 'html2canvas';
import { FlyerConfigs } from './flyerConfig';
import LoaderComponent from '../../components/loader';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { GENERATE_MATERIALS } from '../../constants/route-path';
import SUCCESSTICK from '../../Assets/images/success-tick.png';
import AIRARECT from '../../Assets/images/Aira_Logo_purple.png';

const GenerateMaterials = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);

  const propValue = searchParams.get('pid');
  const templateValue = searchParams.get('tid');
  const [coverImage, setCoverImage] = useState('');
  const [selectedproperty, setSelectedProperty] = useState({});
  const [selectedFlyer, setSelectedFlyer] = useState({});
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentTemplateIndex, setCurrentTemplateIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [selectedFlyerIndex, setSelectedFlyerIndex] = useState(0); // Track which flyer set is active

  useEffect(() => {
    setLoading(true);

    if (propValue) {
      apis.property
        .getListingPropertiesByID({ id: propValue })
        .then((response) => {
          setLoading(false);
          if (response.status === 200) {
            const [property] = response?.data?.property;
            if (property?.files && property?.files.length > 0) {
              let coverImg = property?.files?.find((item) => {
                return item.is_cover_picture;
              });
              if (coverImg?.file_id) {
                setCoverImage(coverImg?.file_url);
              }
            }
            setSelectedProperty(property);
          } else {
            setSelectedProperty('');
          }
        })
        .catch((err) => {
          setLoading(false);
          console.log('Error fetching property by ID', err);
        });
    }
    if (templateValue) {
      const selectedFlyer = FlyerConfigs[templateValue];
      setSelectedFlyer(selectedFlyer);
      setCurrentTemplateIndex(Number(templateValue))
      setSelectedFlyerIndex(0);
    }
  }, [propValue, templateValue]);

  //   const captureFlyer = async (element, width, height) => {
  //      // Store the original dimensions and styles of the flyer element
  //      const originalWidth = element.style.width;
  //      const originalHeight = element.style.height;
  //      const originalTransform = element.style.transform;
  //      const originalPadding = element.style.padding;
  //      const originalMargin = element.style.margin;

  //      // Remove extra padding and margins
  //      element.style.padding = "0";
  //      element.style.margin = "0";

  //      // Set the flyer element to the target resolution
  //      element.style.width = `${width}px`;
  //      element.style.height = `${height}px`;

  //      // Calculate the scaling factor to fit the content within the target resolution
  //      const contentWidth = element.scrollWidth;
  //      const contentHeight = element.scrollHeight;
  //      const scaleX = width / contentWidth;
  //      const scaleY = height / contentHeight;
  //      const scale = Math.min(scaleX, scaleY);

  //      // Apply scaling to the flyer element
  //      element.style.transform = `scale(${scale})`;
  //      element.style.transformOrigin = "top left";

  //      // Capture the flyer with html2canvas
  //      const canvas = await html2canvas(element, {
  //        useCORS: true, // Enable CORS for external images
  //        scale: 1, // Use scale 1 to avoid scaling issues
  //        width: width,
  //        height: height,
  //        // windowWidth: width,
  //        // windowHeight: height,
  //        backgroundColor: null, // Set background to transparent
  //        logging: true, // Enable logging for debugging
  //      });

  //      // Reset the flyer element to its original dimensions and styles
  //      element.style.width = originalWidth;
  //      element.style.height = originalHeight;
  //      element.style.transform = originalTransform;
  //      element.style.padding = originalPadding;
  //      element.style.margin = originalMargin;

  //      return canvas.toDataURL("image/png", 1.0);
  // };

  const switchTemplate = async (index) => {
    return new Promise((resolve) => {
      setSelectedFlyerIndex(index);
      setTimeout(resolve, 700); // Ensure UI updates before capturing
    });
  };

  const captureFlyer = async (index) => {
    const flyerId = `flyer${index + 1}`;
    const element = document.getElementById(flyerId);
    if (!element) return null;
    const canvas = await html2canvas(element, { useCORS: true });
    return canvas.toDataURL('image/png', 1);
  };

  // const handleQRDownload = async (flyerId) => {
  //   const element = document.getElementById(flyerId);
  //   if (!element) {
  //       console.error(`Element with ID ${flyerId} not found.`);
  //       return null;
  //   }

  //   // Temporarily make it visible if it's hidden
  //   const originalDisplay = element.style.display;
  //   element.style.display = "block";

  //   // Capture the element
  //   const canvas = await html2canvas(element, { useCORS: true });
  //   const data = canvas.toDataURL("image/png", 1);

  //   // Restore original display
  //   element.style.display = originalDisplay;

  //   return data;
  // };

  // Function to download the active flyer in different resolutions as a ZIP file
  const downloadFlyerInResolutions = async () => {
    const zip = new JSZip();
    const imgFolder = zip.folder('flyer_resolutions');

    for (let i = 0; i < selectedFlyer?.templates?.length; i++) {
      await switchTemplate(i); // Update the template index and wait for re-render
      const imageData = await captureFlyer(i);
      if (!imageData) continue;

      // Convert image to blob and add to ZIP
      const blob = await fetch(imageData).then((res) => res.blob());
      imgFolder.file(`flyer${i + 1}.png`, blob, { binary: true });
    }

    // Generate and download ZIP
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `flyers.zip`);
    });
  };
  const handleNextFlyer = () => {
    setSelectedFlyerIndex((prevIndex) =>
      prevIndex === selectedFlyer?.templates?.length - 1 ? 0 : prevIndex + 1
    );
  };

  const handlePrevFlyer = () => {
    setSelectedFlyerIndex((prevIndex) =>
      prevIndex === 0 ? selectedFlyer?.templates?.length - 1 : prevIndex - 1
    );
  };

  const handleNextTemplate = () => {
    if (currentTemplateIndex < FlyerConfigs.length - 1) {
      setCurrentTemplateIndex((prevIndex) => prevIndex + 1);
      navigate(
        `${GENERATE_MATERIALS}?pid=${propValue}&tid=${currentTemplateIndex + 1}`
      );
      // setCurrentTemplateIndex(0); // Reset to first template of new flyer
    }
  };

  const handlePrevTemplate = () => {
    if (currentTemplateIndex > 0) {
      setCurrentTemplateIndex((prevIndex) => prevIndex - 1);
      navigate(
        `${GENERATE_MATERIALS}?pid=${propValue}&tid=${currentTemplateIndex - 1}`
      );

      // setCurrentTemplateIndex(0); // Reset to first template of new flyer
    }
  };

  const CurrentFlyer =
    selectedFlyer?.templates?.length &&
    selectedFlyer?.templates[selectedFlyerIndex];

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '85vh',
        mt: '85px',
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      <Box
        sx={{
          padding: '10px',
          //   height: '85vh',
          width: '65%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-start',
          flexDirection: 'row',
          background: '#EFEFEF',
          position: 'relative',
        }}
      >
        <Typography
          sx={{
            position: 'absolute',
            right: '30px',
            top: '20px',
            fontSize: '14px',
            fontWeight: 600,
          }}
        >
          {selectedFlyerIndex + 1}/{selectedFlyer?.templates?.length}
        </Typography>
        <Button
          onClick={handlePrevFlyer}
          disabled={selectedFlyerIndex === 0}
          sx={{
            position: 'absolute',
            left: '30px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <ArrowBack />
        </Button>

        <Button
          onClick={handleNextFlyer}
          disabled={selectedFlyerIndex === selectedFlyer?.templates?.length - 1}
          sx={{
            position: 'absolute',
            right: '30px',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          <ArrowForward />
        </Button>

        <Button
          onClick={handlePrevTemplate}
          variant="outlined"
          disabled={currentTemplateIndex === 0}
          sx={{
            position: 'absolute',
            left: '10px',
            bottom: '10px',
            textTransform: 'capitalize',
          }}
        >
          <ArrowBackIosIcon sx={{ width: '16px' }} />
          Previous Template
        </Button>


        <Button
          onClick={handleNextTemplate}
          variant="outlined"
          disabled={currentTemplateIndex === FlyerConfigs?.length - 1}
          sx={{
            position: 'absolute',
            right: '10px',
            bottom: '10px',
            textTransform: 'capitalize',
          }}
        >
          Next Template
          <ArrowForwardIosIcon sx={{ width: '16px' }} />
        </Button>

        {CurrentFlyer && (
          <CurrentFlyer
            selectedproperty={selectedproperty}
            coverImage={coverImage}
          />
        )}
        {/* {React.cloneElement(FlyerConfigs[0].templates[currentTemplateIndex], { id: `flyer${currentTemplateIndex + 1}` })} */}

        {/* <Flyer1 selectedproperty={selectedproperty} coverImage={coverImage} /> */}
        {/* <Flyer2 selectedproperty={selectedproperty} coverImage={coverImage} /> */}
        {/* { selectedFlyer?.templates[0]  } */}
      </Box>
      <Box
        sx={{
          padding: '25px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'flex-start',
          flexDirection: 'column',
          width: '30%',
          height: '85vh',
        }}
      >
        <Typography
          sx={{
            fontSize: '26px',
            fontWeight: 600,
            mb: '12px',
          }}
        >
          {selectedFlyer?.title}
        </Typography>
        <Typography
          sx={{
            display: 'flex',
            color: '#7D7D7D',
            flexDirection: 'row',
            fontSize: '14px',
          }}
        >
          By{' '}
          <Typography
            sx={{
              fontWeight: 600,
              ml: '3px',
              fontSize: '14px',
              color: '#600DB4',
            }}
          >
            AIRA{' '}
          </Typography>{' '}
          <img
            src={AIRARECT}
            style={{ width: '18px', marginLeft: '5px' }}
            alt="aira logo"
          />{' '}
        </Typography>
        <Divider sx={{ opacity: 0.7, width: '100%', color: 'red', my: 4 }} />
        <Box sx={{ width: '100%' }}>
          <Typography
            sx={{ color: '#474747', fontSize: '16px', fontWeight: 600, mb: 2 }}
          >
            Attributes
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Typography
              sx={{ width: '45%', color: '#7D7D7D', fontSize: '14px', my: 1 }}
            >
              File Type
            </Typography>
            <Typography
              sx={{ width: '55%', my: 1, color: '#474747', fontSize: '14px' }}
            >
              PNG
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Typography
              sx={{ width: '45%', my: 1, color: '#7D7D7D', fontSize: '14px' }}
            >
              Dimensions
            </Typography>
            <Typography
              sx={{ width: '55%', my: 1, color: '#474747', fontSize: '14px' }}
            >
              1920(w) x 1080(h)
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <Typography
              sx={{ width: '45%', my: 1, color: '#7D7D7D', fontSize: '14px' }}
            >
              Suitable for
            </Typography>
            <Typography
              sx={{ width: '55%', my: 1, color: '#474747', fontSize: '14px' }}
            >
              Instagram, LinkedIn Post
            </Typography>
          </Box>
        </Box>

        <Button
          variant="contained"
          sx={{
            my: 2,
            textTransform: 'capitalize',
          }}
          onClick={downloadFlyerInResolutions}
        >
          Download Flyer
        </Button>
        <Box sx={{ mt: 2 }}>
          <Typography sx={{ color: '#474747', fontWeight: 600 }}>
            Property Details
          </Typography>
          <Box
            sx={{ width: '100%', mt: 2, display: 'flex', flexDirection: 'row' }}
          >
            <Box sx={{ width: '35%', borderRadius: '8px', height: '300px' }}>
              <img
                src={coverImage}
                alt="cover-image"
                style={{
                  width: '100%',
                  height: '120px',
                  objectFit: 'cover',
                  borderRadius: '8px',
                }}
              />
            </Box>
            <Box
              sx={{
                width: '65%',
                ml: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  color: '#474747',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                }}
              >
                {selectedproperty?.property_title}{' '}
                <img
                  src={SUCCESSTICK}
                  alt="profile"
                  style={{ width: '18px', marginLeft: '5px' }}
                />{' '}
              </Typography>
              <Typography sx={{ color: '#7D7D7D', fontSize: '14px', my: 1 }}>
                {selectedproperty?.locality}
              </Typography>
              <Typography sx={{ fontSize: '14px', color: '#474747', mb: 2 }}>
                {selectedproperty?.property_type
                  ? `${selectedproperty?.property_type} |`
                  : ''}{' '}
                {selectedproperty?.furnish_type
                  ? `${selectedproperty?.furnish_type} |`
                  : ''}{' '}
                {selectedproperty?.built_up_area} Sq. ft.{' '}
              </Typography>
              <Typography
                sx={{ color: '#474747', fontSize: '18px', fontWeight: 600 }}
              >
                {selectedproperty?.sale_price
                  ? numDifferentiation(selectedproperty?.sale_price)
                  : numDifferentiation(selectedproperty?.rent_amount)}
                {selectedproperty?.property_sale_type === 'Rent' && ' p.m'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {loading && <LoaderComponent />}
    </Box>
  );
};

export default GenerateMaterials;
