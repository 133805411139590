import { Avatar, Box, Typography } from '@mui/material';
import React from 'react';
import vREFYD_WHITE from '../../../Assets/images/vREfyd_logo.png';
import SUCCESSTICK from '../../../Assets/images/success-tick.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { numDifferentiation } from '../../../Utils/helperUtils';

const Flyer1 = ({ selectedproperty, coverImage }) => {
  return (
    <Box
      id="flyer1"
      sx={{
        width: '480px',
        height: '601px',
        margin: '10px',
        backgroundImage: `url(${coverImage})`, 
        backgroundSize: 'cover', 
        backgroundPosition: 'center', 
        backgroundRepeat: 'no-repeat',
        position: 'relative', 
        overflow: 'hidden',
      }}
    >
      <Box
        sx={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '20%', 
          background:
            'linear-gradient(180deg, rgba(10,10,10,1) 0%, rgba(142,142,142,0) 100%)',
        }}
      ></Box>
      <Box
        sx={{
          width: '100%',
          position: 'absolute',
          top: '6px',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginLeft: '30px',
            marginTop: '30px',
            zIndex: 0,
          }}
        >
          <Avatar
            src={selectedproperty?.profile_picture}
            sx={{
              width: '40px',
              height: '40px',
              objectFit: 'cover',
              zIndex: 0,
            }}
          />
          <Box
            sx={{
              marginLeft: '10px',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              justifyContent: 'center',
            }}
          >
            <Typography sx={{ color: '#FEFEFE', fontSize: '10px' }}>
              Listed by
            </Typography>
            <Typography
              sx={{
                color: '#FFFFFF',
                fontSize: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {selectedproperty?.created_by}
              {selectedproperty?.is_verified && (
                <img
                  src={SUCCESSTICK}
                  alt="profile"
                  className="agent-verified-icon"
                  style={{ zIndex: 1 }}
                />
              )}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Typography
        sx={{
          position: 'absolute',
          top: '10px',
          right: '10px',
          display: 'flex',
          marginRight: '10px',
          justifyContent: 'center',
          alignItems: 'center',
          color: '#FEFEFE',
          fontSize: '12px',
        }}
      >
        Made with the Magic of
        <img src={vREFYD_WHITE} width={55} alt="vREfyd" />
      </Typography>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
        }}
      >
        <Typography
          sx={{
            fontWeight: 700,
            textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
            color: '#FFFFFF',
            fontSize: '18px',
            textAlign: 'left',
          }}
        >
          {' '}
          For {selectedproperty?.property_sale_type}
        </Typography>
        <Typography
          sx={{
            fontWeight: 700,
            textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
            fontFamily: 'Inter !Important',
            letterSpacing: '-2px',

            color: '#FFFFFF',
            fontSize: '56px',
            textAlign: 'center',
          }}
        >
          {selectedproperty?.sale_price
            ? numDifferentiation(selectedproperty?.sale_price)
            : numDifferentiation(selectedproperty?.rent_amount)}
        </Typography>
        <Typography
          sx={{
            color: '#FFFFFF',
            textShadow: '0px 20.91px 188.21px rgba(0, 0, 0, 0.25)',
            fontFamily: 'Inter !Important',
            fontWeight: 700,
          }}
        >
          {selectedproperty?.property_sale_type === 'Rent' && ' per month'}
        </Typography>
      </Box>

      <Box
        sx={{
          zIndex: 1,
          position: 'absolute',
          bottom: '25px',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{
            color: '#FFFFFF',
            textAlign: 'center',
            fontSize: '14px',
            width: '50%',
          }}
        >
          {selectedproperty?.property_title}
        </Typography>
        {/* <Typography>{selectedproperty?.locality}</Typography> */}
      </Box>

      <Box
        sx={{
          position: 'absolute',
          right: 15,
          bottom: 30,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <ArrowForwardIcon
          color="#FFFFFF"
          sx={{
            color: '#FFFFFF',
            zIndex: 1,
          }}
        />
        <Typography
          sx={{
            color: '#ABABAB',
            zIndex: 1,
            fontSize: '12px',
          }}
        >
          Swipe
        </Typography>
      </Box>

      <Box
        sx={{
          content: '""',
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          zIndex: 0,
          height: '20%', // Adjust as needed
          background:
            'linear-gradient(0deg, rgba(10,10,10,1) 0%, rgba(142,142,142,0) 100%)',
        }}
      ></Box>
    </Box>
  );
};

export default Flyer1;
