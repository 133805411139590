import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import TextFieldWithLabel from '../../../../components/textFieldWithLabel';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import INSTAICON from '../../../../Assets/images/insta-icon.png';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../../../../Utils/translations';

export default function BrandDetails({
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
}) {
  const { t } = useTranslation();
  return (
    <Box>
      <Grid
        container
        columnGap={4}
        sx={{
          border: '1px solid #EAEAEA',
          borderRadius: '16px',
          padding: { lg: '2rem', xs: '1rem' },
          marginTop: '1rem',
          justifyContent: 'space-between',
        }}
      >
        <Grid item lg={12} sx={{ marginBottom: '1.5rem' }}>
          <Typography sx={{ color: '#474747', fontSize: '16px' }}>
            {t(TRANSLATIONS.SUB_TITLES.SOCIAL_LINKS)}
          </Typography>
        </Grid>

        <TextFieldWithLabel
          name="instaPageLink"
          placeholder="https://instagram.com/your-account"
          label={t(TRANSLATIONS.SUB_TITLES.INSTAGRAM_PAGE_LINK)}
          value={values.instaPageLink}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.instaPageLink && Boolean(errors.instaPageLink)}
          helperText={touched.instaPageLink && errors.instaPageLink}
          icon={
            <img
              src={INSTAICON}
              alt="icon"
              style={{
                width: '25px',
                height: '25px',
              }}
            />
          }
        />

        <TextFieldWithLabel
          name="fbPageLink"
          placeholder="https://facebook.com/your-account"
          label={t(TRANSLATIONS.SUB_TITLES.FACEBOOK_PAGE_LINK)}
          value={values.fbPageLink}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.fbPageLink && Boolean(errors.fbPageLink)}
          helperText={touched.fbPageLink && errors.fbPageLink}
          icon={
            <FacebookIcon
              sx={{ color: '#1976D2', width: '30px', height: '30px' }}
            />
          }
        />

        <TextFieldWithLabel
          name="linkedInPageLink"
          placeholder="https://linkedin.com/in/your-account/"
          label={t(TRANSLATIONS.SUB_TITLES.LINKEDIN_PAGE_LINK)}
          value={values.linkedInPageLink}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.linkedInPageLink && Boolean(errors.linkedInPageLink)}
          helperText={touched.linkedInPageLink && errors.linkedInPageLink}
          icon={
            <LinkedInIcon
              sx={{ color: '#0B63BD', width: '30px', height: '30px' }}
            />
          }
        />

        <TextFieldWithLabel
          name="twitterPageLink"
          placeholder="https://youtube.com/your-account"
          label={t(TRANSLATIONS.SUB_TITLES.YOUTUBE_PAGE_LINK)}
          value={values.twitterPageLink}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.twitterPageLink && Boolean(errors.twitterPageLink)}
          helperText={touched.twitterPageLink && errors.twitterPageLink}
          icon={
            <YouTubeIcon
              sx={{ color: '#E60000', width: '30px', height: '30px' }}
            />
          }
        />
      </Grid>
    </Box>
  );
}
