import FLYERIMG1 from './../../Assets/images/Flyers/Flyer1.png'
import FLYERIMG2 from './../../Assets/images/Flyers/Flyer2.jpg'
import Flyer1 from './FlyerTemplates/flyer1'
import Flyer2 from './FlyerTemplates/flyer2'
import Flyer3 from './FlyerTemplates/flyer3'




export const FlyerConfigs = [
    { id: 'flyertemplate1', title:'Glorious', image: FLYERIMG1 , templates:[Flyer1,Flyer2] },
    { id: 'flyertemplate2', title:'Vibe', image: FLYERIMG2 , templates:[Flyer3,Flyer2] },
    // { id: 'flyertemplate3', title:'Flipside', image: FLYERIMG3 , templates:[Flyer1,Flyer2] },
    // { id: 'flyertemplate4', title:'Pulsewave', image: FLYERIMG4 , templates:[Flyer1,Flyer2] },
    // { id: 'flyertemplate5', title:'Crescendo', image: FLYERIMG5 , templates:[Flyer1,Flyer2]  },
    // { id: 'flyertemplate6', title:'Mirage', image: FLYERIMG6 , templates:[Flyer1,Flyer2] },
    // { id: 'flyertemplate7', title:'Horizon', image: FLYERIMG7 , templates:[Flyer1,Flyer2] },
    // { id: 'flyertemplate8', title:'Eclipse', image: FLYERIMG8 , templates:[Flyer1,Flyer2] },
  ];
  
// Flyer Template Names 
// 1. Spark
// 2. Pulse
// 3. Flash
// 4. Edge
// 5. Ignite
// 6. Zenith
// 7. Beacon
// 8. Crest
// 9. Radiance
// 10. Glimpse
// 11. Vivid
// 12. Prism
// 13. Nova
// 14. Surge
// 15. Aura
// 16. Blaze
// 17. Flare
// 18. Flicker
// 19. Glint
// 20. Shine
// 21. Lustre
// 22. Gleam
// 23. Twinkle
// 24. Dazzle
// 25. Momentum
// 26. Flourish
// 27. Flair
// 28. Gusto
// 29. Pizzazz
// 30. Verve
// 31. Fervor
// 32. Radiant
// 33. Sparkle
// 34. Shimmer
// 35. Lumen
// 36. Fusion
// 37. Apex
// 38. Eclipse
// 39. Horizon
// 40. Mirage
// 41. Crescendo
// 42. Pulsewave
// 43. Flipside
// 44. Vibe
// 45. Glorious
// ---
// Video Template Names 
// 1. Vibe
// 2. Reel
// 3. Stream
// 4. Glide
// 5. Echo
// 6. Wave
// 7. Zoom
// 8. Flick
// 9. Flow
// 10. Tempo
// 11. Rush
// 12. Snap
// 13. Burst
// 14. Momentum
// 15. Cascade
// 16. Motion
// 17. Drift
// 18. Sway
// 19. Clip
// 20. Slice
// 21. Dash
// 22. Flicker
// 23. Twist
// 24. Shift
// 25. Loop
// 26. Vortex
// 27. Slide
// 28. Sizzle
// 29. Ripple
// 30. Frame
// 31. Chrono
// 32. Streak
// 33. Beat
// 34. Riff
// 35. Rhythm
// 36. Cue
// 37. Strobe
// 38. Jolt
// 39. Flashback
// 40. Montage
// 41. Reverie
// 42. Kaleido
// 43. Zoomify
// 44. Cinematic
// 45. Spectrum
