import React, { useState, Suspense, useEffect, useRef } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import SignupLoginForm from './Pages/SignupLogin/SignupLoginForm';
import './App.css';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import './Utils/i18n';
import {
  LOGIN,
  VERIFY_OTP,
  OTP_SUCCESS,
  CHAT_WITH_AIRA,
  FOUR_0_FOUR,
  HOME,
  UPDATE_DETAILS,
  MESSAGES,
  DASHBOARD,
  ADD_PROPERTY,
  PROFILE_ONBOARDING,
  IMAGE_VERIFICATION,
  CREATE_PROFILE,
  INITIATE_EKYC,
  DECLARATION,
  PROPERTY_DETAILS,
  PROFILE_PREVIEW,
  GENERATE_MATERIALS,
  GENERATE_MATERIALS_LIST,
} from './constants/route-path';
import LoaderComponent from './components/loader';
import ErrorBoundary from './components/errorBoundary';
import Protected from './Utils/protectedRoute';
import { loginActions } from './Store/loginSlice';
import Navbar from './components/navbar';
import LocationChangeListener from './components/ LocationChangeListener';
import { userActions } from './Store/userSlice';
import ViewProfile from './Pages/Broker/ViewProfile';
import { onMessage } from 'firebase/messaging';
import { messaging } from './firebaseUtils';
import PushNotification from './components/PushNotification';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { LOCAL_STORAGE_DATA, SOCKETCHATURL } from './constants/app-constants';
import { getAccessToken } from './Utils/helperUtils';
import { HelmetProvider } from 'react-helmet-async';
import GenerateMaterials from './Pages/GenerateMaterials/generateMaterials';
import GenerateMaterialContainer from './Pages/GenerateMaterials/generateMaterialContainer';

const ChatWindow = React.lazy(() => import('./Pages/ChatWindow/ChatWindow'));
const OTPVerificationForm = React.lazy(() =>
  import('./Pages/OTPVerification/OTPVerificationForm')
);

const Messages = React.lazy(() => import('./Pages/Messages/messages'));
const Home = React.lazy(() => import('./Pages/Home/home'));

const HomeNew = React.lazy(() => import('./Pages/Home-new/home-new'));
const SuccessScreen = React.lazy(() => import('./Pages/OTPSuccess/SuccessScreen'));
const Dashboard = React.lazy(() => import('./Pages/Dashboard/dashboard'));

const UpdateDetails = React.lazy(() =>
  import('./Pages/UpdateDetails/updateDetails')
);
const AddProperty = React.lazy(() => import('./Pages/AddProperty'));

const ProfileOnboarding = React.lazy(() =>
  import('./Pages/BrokerBenefits/BrokerBenefits')
);
const CreateProfile = React.lazy(() => import('./Pages/Broker/CreateProfile'));
const ImageVerification = React.lazy(() =>
  import('./Pages/Broker/ImageVerification')
);
const InitiateEKYC = React.lazy(() => import('./Pages/Broker/InitiateEKYC'));
const Declaration = React.lazy(() => import('./Pages/Broker/Declaration'));
const PropertyDetails = React.lazy(() => import('./Pages/PropertyDetails'));
const ProfilePreview = React.lazy(() => import('./Pages/ProfilePreview'));

const NotFound = React.lazy(() => import('./components/notFound'));

const theme = createTheme({
  palette: {
    primary: {
      main: '#600DB4',
      paper: '#fff',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 500,
      xmd: 788,
      md: 1000,
      lg: 1150,
      xl: 1536,
    },
  },
});

const App = () => {
  const NOT_ALLOWED_NAVBAR = [
    FOUR_0_FOUR,
    LOGIN,
    UPDATE_DETAILS,
    VERIFY_OTP,
    OTP_SUCCESS,
    CHAT_WITH_AIRA,
  ];
  const [selectedChat] = useState(null);
  const [chats] = useState([]);
  const [curPath, setCurPath] = useState('');
  const [selectedNavLink, setSelectedNavLink] = useState(1);

  const [unlockedChatsList, setUnlockedChatsList] = useState([]);
  const [leadChatsList, setLeadChatsList] = useState([]);
  const [unreadUnlocks, setUnreadUnlocks] = useState(0);
  const [unreadLeads, setUnreadleads] = useState(0);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [totalUnreadCount, setTotalUnreadCount] = useState(0);
  const { isLoggedIn } = useSelector((state) => state.login || {});

  const dispatch = useDispatch();
  const chatSocket = useRef(null);
  const reconnectTimeout = useRef(null);

  useEffect(() => {
    if (!isLoggedIn) return;

    const getToken = () =>
      getAccessToken() || localStorage.getItem(LOCAL_STORAGE_DATA.ACCESS_TOKEN);

    let reconnectAttempts = 0;
    let accessToken = getToken();

    if (!accessToken) return;

    const connectWebSocket = () => {
      if (reconnectAttempts >= 3) {
        console.warn(
          'Max reconnect attempts reached. Stopping further attempts.'
        );
        return;
      }
      const socketURL = `${SOCKETCHATURL}${getToken()}`;

      console.log('Connecting WebSocket...');
      chatSocket.current = new WebSocket(socketURL);

      chatSocket.current.onopen = () => {
        console.log('WebSocket is open now.');
        reconnectAttempts = 0;
        clearTimeout(reconnectTimeout.current);
      };

      chatSocket.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (data) {
          setLeadChatsList(data.data.leads);
          setUnlockedChatsList(data.data.unlocked);
          setUnreadUnlocks(data.data.unlocked_total_unread);
          setUnreadleads(data.data.leads_total_unread);
          setNotificationsCount(data.data.notifications_count);
          setTotalUnreadCount(data.data.total_unread);
        }

        console.log(data, 'socket-data');

        if (data.error === 'Invalid Token') {
          console.error('Invalid token detected. Attempting token refresh...');
          chatSocket.current.close();
        }
      };

      chatSocket.current.onclose = (event) => {
        if (!event.wasClean) {
          console.warn(
            `WebSocket closed unexpectedly. Attempt ${
              reconnectAttempts + 1
            }/3. Reconnecting in 5 seconds...`
          );
          reconnectAttempts++;
          if (reconnectAttempts < 3) {
            reconnectTimeout.current = setTimeout(connectWebSocket, 2000);
          } else {
            console.error('WebSocket failed to reconnect after 3 attempts.');
          }
        } else {
          console.log('WebSocket closed cleanly.');
        }
      };

      chatSocket.current.onerror = (error) => {
        console.error('WebSocket error:', error);
        chatSocket.current.close();
      };
    };

    connectWebSocket(); // Initial connection

    return () => {
      if (chatSocket.current) {
        chatSocket.current.onclose = null;
        chatSocket.current.onerror = null;
        chatSocket.current.onmessage = null;
        chatSocket.current.close();
      }
      clearTimeout(reconnectTimeout.current);
    };
  }, [isLoggedIn]);

  
  const handlePathChange = (path) => {
    if (path === DASHBOARD) {
      setSelectedNavLink(2);
    } else if (path === HOME) {
      setSelectedNavLink(1);
    } else if (path === MESSAGES) {
      setSelectedNavLink(3);
    } else {
      setSelectedNavLink(0);
    }

    setCurPath(path);
  };

  const handleLogout = () => {
    localStorage.clear();
    dispatch(userActions.clearUserData());
    dispatch(loginActions.loggedOut());
    window.location.replace(LOGIN);
  };

  const handleNewMessage = (message, chatTitle) => {};

  onMessage(messaging, (payload) => {
    console.log('Message received. ', payload);

    const audio = new Audio('../notification-bell.wav');
    audio.play();

    toast.info(<PushNotification notification={payload.notification} />, {
      position: 'top-right',
      autoClose: true,
      hideProgressBar: false,
      progressStyle: { background: '#600DB4' },
      closeOnClick: false,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
      style: { lineHeight: 1.4 },
      onClose: () => toast.done(),
      icon: () => (
        <img
          style={{ width: '20px', height: '20px' }}
          src="./fav-icon-vrefyd.jpg"
          alt="icon"
        />
      ),
    });
  });

  return (
    <HelmetProvider>
      <ThemeProvider theme={theme}>
        <Suspense fallback={<LoaderComponent />}>
          <ErrorBoundary>
            <Router>
              <LocationChangeListener handlePathChange={handlePathChange} />
              {!NOT_ALLOWED_NAVBAR.includes(curPath) && (
                <Navbar
                  handleLogout={handleLogout}
                  selectedNavLink={selectedNavLink}
                  setSelectedNavLink={setSelectedNavLink}
                  totalUnreadCount={totalUnreadCount}
                  notificationsCount={notificationsCount}
                />
              )}

              <ToastContainer />

              <Routes>
                <Route
                  path={LOGIN}
                  element={
                    !isLoggedIn ? (
                      <SignupLoginForm />
                    ) : (
                      <Navigate to={HOME} replace />
                    )
                  }
                />
                <Route
                  path={MESSAGES}
                  element={
                    <Protected>
                      <Messages
                        unlockedChatsList={unlockedChatsList}
                        leadChatsList={leadChatsList}
                        unreadUnlocks={unreadUnlocks}
                        unreadLeads={unreadLeads}
                        chatSocket={chatSocket}
                      />
                    </Protected>
                  }
                />
                <Route
                  path={DASHBOARD}
                  element={
                    <Protected>
                      <Dashboard />
                    </Protected>
                  }
                />
                 <Route
                  path={GENERATE_MATERIALS}
                  element={
                    <Protected>
                      <GenerateMaterials />
                    </Protected>
                  }
                />
                 <Route
                  path={GENERATE_MATERIALS_LIST}
                  element={
                    <Protected>
                      <GenerateMaterialContainer />
                    </Protected>
                  }
                />
                <Route
                  path={HOME}
                  element={
                    <Protected>
                      {/* <Home /> */}
                      <HomeNew />
                    </Protected>
                  }
                />
                <Route
                  path={VERIFY_OTP}
                  element={
                    !isLoggedIn ? (
                      <OTPVerificationForm />
                    ) : (
                      <Navigate to={HOME} replace />
                    )
                  }
                />
                <Route path={OTP_SUCCESS} element={<SuccessScreen />} />
                <Route
                  path={UPDATE_DETAILS}
                  element={
                    <Protected>
                      <UpdateDetails />
                    </Protected>
                  }
                />
                <Route
                  path={CHAT_WITH_AIRA}
                  element={
                    <Protected>
                      <ChatWindow
                        selectedChat={selectedChat}
                        chats={chats}
                        onNewMessage={handleNewMessage}
                      />
                    </Protected>
                  }
                />
                <Route
                  path={ADD_PROPERTY}
                  element={
                    <Protected>
                      <AddProperty />
                    </Protected>
                  }
                />
                <Route path="/profile" element={<ViewProfile />} />
                <Route
                  path={PROFILE_ONBOARDING}
                  element={
                    <Protected>
                      <ProfileOnboarding />
                    </Protected>
                  }
                />
                <Route
                  path={CREATE_PROFILE}
                  element={
                    <Protected>
                      <CreateProfile />
                    </Protected>
                  }
                />

                <Route
                  path={IMAGE_VERIFICATION}
                  element={
                    <Protected>
                      <ImageVerification />
                    </Protected>
                  }
                />
                <Route
                  path={DECLARATION}
                  element={
                    <Protected>
                      <Declaration />
                    </Protected>
                  }
                />
                <Route
                  path={INITIATE_EKYC}
                  element={
                    <Protected>
                      <InitiateEKYC />
                    </Protected>
                  }
                />
                <Route
                  path={PROPERTY_DETAILS}
                  element={
                    <Protected>
                      <PropertyDetails />
                    </Protected>
                  }
                />
                <Route
                  path={PROFILE_PREVIEW}
                  element={
                    <Protected>
                      <ProfilePreview />
                    </Protected>
                  }
                />
                <Route path={FOUR_0_FOUR} element={<NotFound />} />
              </Routes>
            </Router>
          </ErrorBoundary>
        </Suspense>
      </ThemeProvider>
    </HelmetProvider>
  );
};

export default App;
