import { TRANSLATIONS } from '../translations';

const translationEnglish = {
  // titles
  [TRANSLATIONS.TITLES.HOME]: 'Home',
  [TRANSLATIONS.TITLES.PROPERTIES]: 'Properties',
  [TRANSLATIONS.TITLES.MESSAGES]: 'Messages',
  [TRANSLATIONS.TITLES.AGENT_ID]: 'Agent Id',
  [TRANSLATIONS.TITLES.LOGOUT]: 'Logout',
  [TRANSLATIONS.TITLES.PROFILE]: 'Profile',
  [TRANSLATIONS.TITLES.ASK_AIRA]: 'Ask AIRA...',
  [TRANSLATIONS.TITLES.SAVED]: 'Saved',
  [TRANSLATIONS.TITLES.UNLOCKED]: 'Unlocked',
  [TRANSLATIONS.TITLES.MY_LISTINGS]: 'My Listings',
  [TRANSLATIONS.TITLES.LANGUAGE]: 'Language',
  [TRANSLATIONS.TITLES.SUBMIT]: 'Submit',
  [TRANSLATIONS.TITLES.CHANGE]: 'Change',
  [TRANSLATIONS.TITLES.BACK]: 'Back',
  [TRANSLATIONS.TITLES.DASHBOARD]: 'Dashboard',
  [TRANSLATIONS.TITLES.SAVE]: 'Save',
  [TRANSLATIONS.TITLES.CANCEL]: 'Cancel',
  [TRANSLATIONS.TITLES.SHARE_FEEDBACK]: 'Share Feedback',
  [TRANSLATIONS.TITLES.CITY]: 'City',
  [TRANSLATIONS.TITLES.LOCALITY]: 'Locality',
  [TRANSLATIONS.TITLES.LOOKING_FOR]: 'Looking for',
  [TRANSLATIONS.TITLES.PROPERTY_TYPE]: 'Property type',
  [TRANSLATIONS.TITLES.BEDROOM]: 'Bedroom',
  [TRANSLATIONS.TITLES.BEDROOMS]: 'Bedrooms',
  [TRANSLATIONS.TITLES.FILTERS]: 'Filters',
  [TRANSLATIONS.TITLES.FURNISHING]: 'Furnishing',
  [TRANSLATIONS.TITLES.BUILD_UP_AREA]: 'Built up area',
  [TRANSLATIONS.TITLES.BUDGET]: 'Budget',
  [TRANSLATIONS.TITLES.CLEAR_ALL]: 'Clear All',
  [TRANSLATIONS.TITLES.APPLY]: 'Apply',
  [TRANSLATIONS.TITLES.MIN_BUILD_UP]: 'Min Build Up',
  [TRANSLATIONS.TITLES.MAX_BUILD_UP]: 'Min Build Up',
  [TRANSLATIONS.TITLES.FILTER]: 'filter',
  [TRANSLATIONS.TITLES.AMENITIES]: 'Amenities',
  [TRANSLATIONS.TITLES.NOTIFICATIONS]: 'Notifications',
  [TRANSLATIONS.TITLES.BATHROOMS]: 'Bathrooms',
  [TRANSLATIONS.TITLES.MESSAGE]: 'Message',
  [TRANSLATIONS.TITLES.NAME]: 'Name',
  [TRANSLATIONS.TITLES.PHONE_NUMBER]: 'Phone Number',
  [TRANSLATIONS.TITLES.EMAIL]: 'Email',
  [TRANSLATIONS.TITLES.LANGUAGE_S]: 'Language(s)',
  [TRANSLATIONS.TITLES.REMOVE]: 'Remove',
  [TRANSLATIONS.TITLES.ADDRESS]: 'Address',
  [TRANSLATIONS.TITLES.AGENT]: 'Agent',
  [TRANSLATIONS.TITLES.AREA]: 'Area',
  [TRANSLATIONS.TITLES.VERIFY]: 'Verify',
  [TRANSLATIONS.TITLES.PROCEED]: 'Proceed',
  [TRANSLATIONS.TITLES.STATE]: 'State',
  [TRANSLATIONS.TITLES.PINCODE]: 'Pincode',
  [TRANSLATIONS.TITLES.SELECT]: 'Select',
  [TRANSLATIONS.TITLES.RERA_NUMBER]: 'RERA Number',
  [TRANSLATIONS.TITLES.ADD_MORE_DETAILS]: 'Add more details',
  [TRANSLATIONS.TITLES.RETAKE_REUPLOAD_PHOTO]: 'Retake / Reupload Photo',
  [TRANSLATIONS.TITLES.CLOSED_DEALS]: 'Closed Deals',
  [TRANSLATIONS.TITLES.ACTIVE_LISTING]: 'Active Listing',
  [TRANSLATIONS.TITLES.VERIFICATION]: 'Verification',
  [TRANSLATIONS.TITLES.ABOUT]: 'About',
  [TRANSLATIONS.TITLES.CLOSED]: 'Closed',
  [TRANSLATIONS.TITLES.AVAILABILITY]: 'Availability',
  [TRANSLATIONS.TITLES.ACCESS]: 'ACCESS',
  [TRANSLATIONS.TITLES.TRY_AGAIN]: 'Try Again',
  [TRANSLATIONS.TITLES.LATITUDE]: 'Latitude',
  [TRANSLATIONS.TITLES.LONGITUDE]: 'Longitude',
  [TRANSLATIONS.TITLES.RE_VERIFY]: 'Re-Verify',
  [TRANSLATIONS.TITLES.HIDE]: 'hide',
  [TRANSLATIONS.TITLES.VIEW_ALL]: 'view all',
  [TRANSLATIONS.TITLES.FIND_PROPERTY]: 'Find Property',
  [TRANSLATIONS.TITLES.TAP_THE]: 'Tap the',
  [TRANSLATIONS.TITLES.WE_FOUND]: 'We found',
  [TRANSLATIONS.TITLES.MATCHES_INSTANTLY]: 'Matches Instantly',
  [TRANSLATIONS.TITLES.WE_ARE_ON_IT]: 'We’re on it!',
  [TRANSLATIONS.TITLES.SELECT_CITY]: 'Select City',
  [TRANSLATIONS.TITLES.TYPE]: 'Type',
  [TRANSLATIONS.TITLES.FIND]: 'Find',
  [TRANSLATIONS.TITLES.BALCONIES]: 'Balconies',
  [TRANSLATIONS.TITLES.PROPERTY_CATEGORY]: 'Property Category',
  [TRANSLATIONS.TITLES.OCCUPANCY_STATUS]: 'Occupancy Status',
  [TRANSLATIONS.TITLES.CARPET_AREA]: 'Carpet Area',
  [TRANSLATIONS.TITLES.TOTAL_FLOOR]: 'Total Floor',
  [TRANSLATIONS.TITLES.PROPERTY_ON_FLOOR]: 'Property On Floor',
  [TRANSLATIONS.TITLES.PROPERTY_FACING]: 'Property Facing',
  [TRANSLATIONS.TITLES.BUILT_IN_YEAR]: 'Built in Year',
  [TRANSLATIONS.TITLES.PROPERTY_NAME]: 'Property Name',
  [TRANSLATIONS.TITLES.LIFT]: 'Lift',
  [TRANSLATIONS.TITLES.CAR_PARKING]: 'Car Parking',
  [TRANSLATIONS.TITLES.FURNISHING_TYPE]: 'Furnishing Type',
  [TRANSLATIONS.TITLES.PROPERTY_VALUE]: 'Property Value',
  [TRANSLATIONS.TITLES.LOCK_IN_PERIOD]: 'Lock In Period',
  [TRANSLATIONS.TITLES.RENT_AMOUNT]: 'Rent Amount',
  [TRANSLATIONS.TITLES.SECURITY_DEPOSIT]: 'Security Deposit',
  [TRANSLATIONS.TITLES.MAINTENANCE_AMOUNT]: 'Maintenance Amount',
  [TRANSLATIONS.TITLES.RENT_AVAILABLE_FROM]: 'Rent Available From',
  [TRANSLATIONS.TITLES.PROPERTY_ADDED_ON]: 'Property Added On',
  [TRANSLATIONS.TITLES.TENANT_PREFERENCES]: 'Tenant Preferences',
  [TRANSLATIONS.TITLES.UNSAVE]: 'Unsave',
  [TRANSLATIONS.TITLES.CLOSED_WITH]: 'Closed with',
  [TRANSLATIONS.TITLES.CHAT_UNLOCKED]: 'Chat Unlocked!',
  [TRANSLATIONS.TITLES.BALCONY]: 'Balcony!',
  [TRANSLATIONS.TITLES.INTERESTING_HIGHLIGHTS]: 'Interesting Highlights',
  [TRANSLATIONS.TITLES.ESSENTIAL_INFORMATION]: 'Essential Information',
  [TRANSLATIONS.TITLES.DOCUMENT]: 'Document',
  [TRANSLATIONS.TITLES.UNLOCK_TO_CHAT]: 'Unlock to Chat',
  [TRANSLATIONS.TITLES.PROPERTY_REJECTED]: 'Property Rejected',
  [TRANSLATIONS.TITLES.VERIFY_NOW]: 'Verify Now',
  [TRANSLATIONS.TITLES.RE_VERIFY_NOW]: 'Re-Verify Now',
  [TRANSLATIONS.TITLES.MARK_AS_CLOSED]: 'Mark as Closed',
  [TRANSLATIONS.TITLES.PREVIEW_AND_PERFECT]: 'Preview and Perfect',
  [TRANSLATIONS.TITLES.EDIT_PROFILE]: 'Edit Profile',
  [TRANSLATIONS.TITLES.SHARE_PROFILE]: 'Share Profile',
  [TRANSLATIONS.TITLES.RE_INITIATE_VERIFICATION]: 'Re-Initiate Verification',
  [TRANSLATIONS.TITLES.VERIFICATION_IN_PROGRESS]: 'Verification in progress',
  [TRANSLATIONS.TITLES.VERIFICATION_REJECTED]: 'Verification Rejected',
  [TRANSLATIONS.TITLES.ADDITIONAL_SERVICES]: 'Additional Services',
  [TRANSLATIONS.TITLES.GENERATE]: 'Generate',
  [TRANSLATIONS.TITLES.ADD_CITY]: 'Add City',
  [TRANSLATIONS.TITLES.LISTING_TYPE]: 'Listing Type',
  [TRANSLATIONS.TITLES.COMMERCIAL_PARKING]: 'Commercial Parking',
  [TRANSLATIONS.TITLES.ADD_AMENITY]: 'Add Amenity',
  [TRANSLATIONS.TITLES.ADD]: 'Add',
  [TRANSLATIONS.TITLES.SKIP]: 'Skip',
  [TRANSLATIONS.TITLES.NEXT]: 'Next',
  [TRANSLATIONS.TITLES.MY_LEADS]: 'My Leads',
  [TRANSLATIONS.TITLES.DONE]: 'Done',
  [TRANSLATIONS.TITLES.WELCOME_TO]: 'Welcome to',
  [TRANSLATIONS.TITLES.VIEW_PROFILE]: 'View profile',
  [TRANSLATIONS.TITLES.DEALS_CLOSED]: 'Deals closed',
  [TRANSLATIONS.TITLES.YEARS]: 'Years',
  [TRANSLATIONS.TITLES.FRESHER]: 'Fresher',
  [TRANSLATIONS.TITLES.SPECIALIZATION]: 'Specialization',
  [TRANSLATIONS.TITLES.AGENTS]: 'Agents',
  [TRANSLATIONS.TITLES.TOP]: 'Top',
  [TRANSLATIONS.TITLES.SHARE_NOW]: 'Share now',
  [TRANSLATIONS.TITLES.SEARCH]: 'Search',
  [TRANSLATIONS.TITLES.BUY_PURCHASE]: 'Buy/Purchase',
  [TRANSLATIONS.TITLES.VERIFYING_OTP]: 'Verifying OTP',
  [TRANSLATIONS.TITLES.RESEND_CODE]: 'Resend Code',
  [TRANSLATIONS.TITLES.CONGRATULATIONS]: 'Congratulations',
  [TRANSLATIONS.TITLES.SEARCH_MESSAGES]: 'Search messages',
  [TRANSLATIONS.TITLES.UPLOAD_DOC]: 'Upload doc',
  [TRANSLATIONS.TITLES.BROKERAGE]: 'Brokerage',
  [TRANSLATIONS.TITLES.UNLOCKS]: 'Unlocks',
  [TRANSLATIONS.TITLES.LOCATION]: 'Location',
  [TRANSLATIONS.TITLES.INFO]: 'Info',
  [TRANSLATIONS.TITLES.COMPANY]: 'Company',
  [TRANSLATIONS.TITLES.DESCRIPTION]: 'Description',
  [TRANSLATIONS.TITLES.REGENERATE]: 'Regenerate',
  [TRANSLATIONS.TITLES.REGENERATING_DESCRIPTION]: 'Regenerating description...',
  [TRANSLATIONS.TITLES.ENTER_DESCRIPTION]: 'Enter Description',
  [TRANSLATIONS.TITLES.VERIFYING]: 'Verifying...',
  [TRANSLATIONS.TITLES.POSTED_BY]: 'Posted by:',
  [TRANSLATIONS.TITLES.LOADING]: 'Loading...',
  [TRANSLATIONS.TITLES.SELECT_PROPERTY]: 'Select Property',
  [TRANSLATIONS.TITLES.ALL]: 'All',
  [TRANSLATIONS.TITLES.PROPERTY]: 'Property',
  [TRANSLATIONS.TITLES.SHARE_CARD]: 'Share Card',
  [TRANSLATIONS.TITLES.DOWNLOAD]: 'Download',

  // sub titles
  [TRANSLATIONS.SUB_TITLES.PROPERTY_VERIFICATION]: 'Property Verification',
  [TRANSLATIONS.SUB_TITLES.SELECTED_COORDINATES]: 'Selected Coordinates',
  [TRANSLATIONS.SUB_TITLES.LOCATION_PIN_REQUIRED]: 'Location Pin Required',
  [TRANSLATIONS.SUB_TITLES.PAGE_NOT_FOUND]: 'Page not Found',
  [TRANSLATIONS.SUB_TITLES.FIND_CONNECT_CLOSE_FASTER]:
    'Find, Connect, Close Faster.',
  [TRANSLATIONS.SUB_TITLES.PASTE_PROPERTY_DETAILS_HERE]:
    'Paste your property details here',
  [TRANSLATIONS.SUB_TITLES.AUTO_FILL_WITH]: 'Autofill with',
  [TRANSLATIONS.SUB_TITLES.FILTER_S_ADDED]: 'filter(s) added',
  [TRANSLATIONS.SUB_TITLES.AREAS_OF_OPERATION]: 'Areas of Operation',
  [TRANSLATIONS.SUB_TITLES.LEGAL_BUSINESS_NAME]: 'Legal Business Name',
  [TRANSLATIONS.SUB_TITLES.BRAND_NAME]: 'Brand Name',
  [TRANSLATIONS.SUB_TITLES.GST_NUMBER]: 'GST Number',
  [TRANSLATIONS.SUB_TITLES.SOCIAL_LINKS]: 'Social Links',
  [TRANSLATIONS.SUB_TITLES.INSTAGRAM_PAGE_LINK]: 'Instagram Page Link',
  [TRANSLATIONS.SUB_TITLES.FACEBOOK_PAGE_LINK]: 'Facebook Page Link',
  [TRANSLATIONS.SUB_TITLES.LINKEDIN_PAGE_LINK]: 'LinkedIn Page Link',
  [TRANSLATIONS.SUB_TITLES.YOUTUBE_PAGE_LINK]: 'Youtube Page Link',
  [TRANSLATIONS.SUB_TITLES.PHONE_NUMBER_OF_THE_AGENT]:
    'Phone Number of the Agent',
  [TRANSLATIONS.SUB_TITLES.ADD_A_PROPERTY]: 'Add a Property',
  [TRANSLATIONS.SUB_TITLES.CONNECT_SMARTER]: 'Connect Smarter,',
  [TRANSLATIONS.SUB_TITLES.CLOSE_FASTER]: 'Close Faster with vREfyd.',
  [TRANSLATIONS.SUB_TITLES.AIRA_FIND]: 'FIND what you are looking for...',
  [TRANSLATIONS.SUB_TITLES.NO_PROPERTY]: 'You have not added any property yet',
  [TRANSLATIONS.SUB_TITLES.NOT_JUST_PART_OF_SYSTEM]:
    'You’re Not Just a Part of the System.',
  [TRANSLATIONS.SUB_TITLES.YOU_ARE_THE_SYSTEM]: 'You Are The System!',
  [TRANSLATIONS.SUB_TITLES.JOIN_COMMUNITY]:
    'Join India’s most trusted real estate community',
  [TRANSLATIONS.SUB_TITLES.WELCOME_TO_VREFYD]: 'Welcome to vREfyd',
  [TRANSLATIONS.SUB_TITLES.LETS_BEGIN]: "Let's begin with your phone number",
  [TRANSLATIONS.SUB_TITLES.OTP_NOT_RECEIVED]:
    'Didn’t receive the OTP? Resend in',
  [TRANSLATIONS.SUB_TITLES.ENTER_VERIFICATION_CODE]:
    'Enter the verification code sent to your phone',
  [TRANSLATIONS.SUB_TITLES.PLEASE_CHECK_WHATSAPP_OR_SMS]:
    'Please check WhatsApp/SMS for verification code',
  [TRANSLATIONS.SUB_TITLES.LIST_MY_PROPERTY]: 'List my Property',
  [TRANSLATIONS.SUB_TITLES.CHOOSE_LANGUAGE]: 'Choose Language',
  [TRANSLATIONS.SUB_TITLES.LOADING_NOTIFICATIONS]: 'Loading Notifications....',
  [TRANSLATIONS.SUB_TITLES.NO_NOTIFICATION_FOUND]: 'No notification found',
  [TRANSLATIONS.SUB_TITLES.BY_SUBMITTING]: 'By submitting you agree to our ',
  [TRANSLATIONS.SUB_TITLES.PRIVACY_POLICY]: 'privacy policy',
  [TRANSLATIONS.SUB_TITLES.AND]: 'and',
  [TRANSLATIONS.SUB_TITLES.TERMS_OF_USE]: ' terms of use',
  [TRANSLATIONS.SUB_TITLES.LETS_GET_TO_KNOW_YOU]: 'LET’S GET TO KNOW YOU',
  [TRANSLATIONS.SUB_TITLES.YEARS_OF_EXPERIENCE]: 'Years of Experience',
  [TRANSLATIONS.SUB_TITLES.HIGHLIGHT_YOUR_SUPERPOWERS]:
    'Highlight Your Superpowers',
  [TRANSLATIONS.SUB_TITLES.MY_SPECIALIZATION]: 'My Specialization',
  [TRANSLATIONS.SUB_TITLES.OTHER_SERVICES]: 'Other Services',
  [TRANSLATIONS.SUB_TITLES.SPEAK_THEIR_LANGUAGE]: 'Speak Their Language',
  [TRANSLATIONS.SUB_TITLES.DEFINE_YOUR_AREA_OF_OPERATION]:
    'Define your Area of Operation',
  [TRANSLATIONS.SUB_TITLES.ADD_ANOTHER_CITY]: 'Add another city',
  [TRANSLATIONS.SUB_TITLES.SHOWCASE_YOUR_BRAND]: 'Showcase Your Brand',
  [TRANSLATIONS.SUB_TITLES.BUSINESS_DETAILS]: 'Business Details',
  [TRANSLATIONS.SUB_TITLES.IMAGE_OF_VISITING_CARD]: 'Image of Visiting Card',
  [TRANSLATIONS.SUB_TITLES.IMAGE_OF_SHOP_ESTABLISHMENT]:
    'Image of Shop / Establishment',
  [TRANSLATIONS.SUB_TITLES.COMPANY_PAN_CARD_GST_CERTIFICATE]:
    'Company Pan Card / GST Certificate',
  [TRANSLATIONS.SUB_TITLES.VERIFIED_AGENT_VERIFICATION]:
    'Verified Agent Verification',
  [TRANSLATIONS.SUB_TITLES.FASTEST_IMMEDIATE_VERIFICATION]:
    'Fastest - Immediate Verification',
  [TRANSLATIONS.SUB_TITLES.BUSINESS_VERIFICATION]: 'Business Verification',
  [TRANSLATIONS.SUB_TITLES.RERA_VERIFICATION]: 'RERA Verification',
  [TRANSLATIONS.SUB_TITLES.COMMUNITY_VERIFICATION]: 'Community Verification',
  [TRANSLATIONS.SUB_TITLES.UPLOAD_DOCUMENTS]: 'Upload Documents',
  [TRANSLATIONS.SUB_TITLES.LETS_CAPTURE_YOUR_BEST_LOOK]:
    'Let’s Capture Your Best Look',
  [TRANSLATIONS.SUB_TITLES.POSITION_YOUR_FACE_IN_THE_SQUARE_BELOW]:
    'Position your face in the square below',
  [TRANSLATIONS.SUB_TITLES.SAMPLE_PROFILE_PREVIEW]: 'Sample Profile Preview',
  [TRANSLATIONS.SUB_TITLES.SAMPLE_PROFILE_APPEARS]:
    'Sample Profile – This is how your profile appears',
  [TRANSLATIONS.SUB_TITLES.BACK_TO_HOME]: 'Back to Home',
  [TRANSLATIONS.SUB_TITLES.DEAL_CLOSED_OUTSIDE]: 'The deal was closed outside',
  [TRANSLATIONS.SUB_TITLES.VERIFICATION_INITIATED]: 'Verification Initiated!',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_VERIFICATION_FAILED]:
    'Property Verification Failed',
  [TRANSLATIONS.SUB_TITLES.DUPLICATE_LISTING_DETECTED]:
    'Duplicate Listing Detected',
  [TRANSLATIONS.SUB_TITLES.IMAGE_NOT_AVAILABLE]: 'Image not available',
  [TRANSLATIONS.SUB_TITLES.SAVE_FAV_PROP]: 'Save your favourite properties',
  [TRANSLATIONS.SUB_TITLES.TAP_TO_SAVE]:
    'on any property to add it to your saved properties',
  [TRANSLATIONS.SUB_TITLES.PERSONALIZE_SEARCH]:
    'To personalize your search apply more filters',
  [TRANSLATIONS.SUB_TITLES.SEARCH_FOR_LOCALITY]: 'Search for Locality',
  [TRANSLATIONS.SUB_TITLES.PURCHASE_RENT]: 'Purchase/Rent',
  [TRANSLATIONS.SUB_TITLES.ADVANCE_FILTERS]: 'Advance Filters',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_SALE_TYPE]: 'Property Sale Type',
  [TRANSLATIONS.SUB_TITLES.CHAT_TO_KNOW_MORE]:
    'Chat to know more about the property',
  [TRANSLATIONS.SUB_TITLES.HOUSE_FLAT_BLOCK_NUMBER]:
    'House / Flat / Block Number',
  [TRANSLATIONS.SUB_TITLES.APARTMENT_SOCIETY]: 'Apartment / Society',
  [TRANSLATIONS.SUB_TITLES.ROAD_AREA]: 'ROAD / AREA',
  [TRANSLATIONS.SUB_TITLES.BEDROOMS_ARE_REQUIRED]: 'Bedrooms are required',
  [TRANSLATIONS.SUB_TITLES.HOUSE_FLAT_BLOCK_NUMBER]:
    'House / Flat / Block Number',
  [TRANSLATIONS.SUB_TITLES.APARTMENT_SOCIETY]: 'Apartment / Society',
  [TRANSLATIONS.SUB_TITLES.ROAD_AREA]: 'ROAD / AREA',
  [TRANSLATIONS.SUB_TITLES.BEDROOMS_ARE_REQUIRED]: 'Bedrooms are required',
  [TRANSLATIONS.SUB_TITLES.BALCONIES_ARE_REQUIRED]: 'Balconies are required',
  [TRANSLATIONS.SUB_TITLES.BATHROOMS_ARE_REQUIRED]: 'Bathrooms are required',
  [TRANSLATIONS.SUB_TITLES.LISTING_TYPE_IS_REQUIRED]:
    'Listing type is required',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_CATEGORY_IS_REQUIRED]:
    'Property category is required',
  [TRANSLATIONS.SUB_TITLES.PROPERTY_TYPE_IS_REQUIRED]:
    'Property type is required',
  [TRANSLATIONS.SUB_TITLES.FURNISHING_TYPE_IS_REQUIRED]:
    'Furnishing type is required',
  [TRANSLATIONS.SUB_TITLES.OCCUPANCY_STATUS_IS_REQUIRED]:
    'Occupancy status is required',
  [TRANSLATIONS.SUB_TITLES.BUILD_UP_AREA_IS_REQUIRED]:
    'Build up area is required',
  [TRANSLATIONS.SUB_TITLES.CARPET_AREA_IS_REQUIRED]: 'Carpet area is required',
  [TRANSLATIONS.SUB_TITLES.NO_PROPERTIES_YET]: 'No Properties Yet',
  [TRANSLATIONS.SUB_TITLES.NO_CLOSED_PROPERTIES_YET]:
    'No Closed Properties Yet',
  [TRANSLATIONS.SUB_TITLES.NO_LISTING_AVAILABLE]:
    'No listings available at the moment.',
  [TRANSLATIONS.SUB_TITLES.NO_DEALS_CLOSED]: 'No deals closed yet. Stay tuned!',
  [TRANSLATIONS.SUB_TITLES.PERSONAL_DETAILS]: 'Personal Details',
  [TRANSLATIONS.SUB_TITLES.WELCOME_GLAD_TO_HAVE_YOU_ABOARD]:
    'Welcome! Glad to have you aboard.',
  [TRANSLATIONS.SUB_TITLES.TELL_US_YOUR_NAME]: 'Tell us your name',
  [TRANSLATIONS.SUB_TITLES.YOUR_PREFERRED_EMAIL_ADDRESS]:
    'Your preferred email address',
  [TRANSLATIONS.SUB_TITLES.TRANSACTIONAL_MESSAGES_WHATSAPP]:
    'Transactional messages and updates will be sent to your WhatsApp',
  [TRANSLATIONS.SUB_TITLES.LETS_BEGIN_WITH_PHONE_NUMBER]:
    "Let's begin with your phone number",
  [TRANSLATIONS.SUB_TITLES.NO_CONVERSATIONS_AVAILABLE]:
    'No conversations available.',
  [TRANSLATIONS.SUB_TITLES.NO_SPECIALIZATIONS_ADDED]:
    'No Specializations added',
  [TRANSLATIONS.SUB_TITLES.MATCHING_LISTINGS]: 'Matching Listings',
  [TRANSLATIONS.SUB_TITLES.ENTER_YRS_OF_EXP]: 'Enter the years of experience',
  [TRANSLATIONS.SUB_TITLES.START_BUILDING]: 'Start Building',
  [TRANSLATIONS.SUB_TITLES.RETRY_VERIFICATION]: "'Retry Verification'",
  [TRANSLATIONS.SUB_TITLES.PROFILE_VERIFICATION_REJECTED]:
    'Profile Verification Rejected',
  [TRANSLATIONS.SUB_TITLES.JOIN_THE_COMMUNITY_ALONG]:
    'Join the community along with',
  [TRANSLATIONS.SUB_TITLES.VREFYD_AGENTS]: 'vREfyd agents',
  [TRANSLATIONS.SUB_TITLES.DIRECT_BUYER_TENANT_LEADS]:
    'Direct Buyer/Tenant Leads',
  [TRANSLATIONS.SUB_TITLES.STAGING_AND_VISUALIZATION]:
    'Staging & Visualization',
  [TRANSLATIONS.SUB_TITLES.FASTER_AGREEMENTS]: 'Faster Agreements',
  [TRANSLATIONS.SUB_TITLES.EASY_FINANCING]: 'Easy Financing',
  [TRANSLATIONS.SUB_TITLES.DIRECT_OWNER_AND_BUILDER_PROPERTIES]:
    'Direct Owner & Builder Properties',
  [TRANSLATIONS.SUB_TITLES.VERIFY_AS_AN_AGENT]: 'Verify as an Agent',
  [TRANSLATIONS.SUB_TITLES.BOOST_YOUR_BROKERAGE]:
    'Boost Your Brokerage with Real Advantages',
  [TRANSLATIONS.SUB_TITLES.UNLIMITED_LISTINGS]: 'Unlimited Listings',
  [TRANSLATIONS.SUB_TITLES.GROW_YOUR_BUSINESS]: 'Grow Your Business',
  [TRANSLATIONS.SUB_TITLES.COMPLETE_DATA_SECURITY]: 'Complete Data Security',
  [TRANSLATIONS.SUB_TITLES.KEEP_ALL_EARNINGS]: 'Keep All Earnings',
  [TRANSLATIONS.SUB_TITLES.OUR_PROPOSITION]: 'Our Proposition',
  [TRANSLATIONS.SUB_TITLES.EDGE_FOR_AGENTS]: 'Edge for Agents',
  [TRANSLATIONS.SUB_TITLES.WIDER_REACH]: 'Wider Reach',
  [TRANSLATIONS.SUB_TITLES.BETTER_TARGETING]: 'Better Targeting',
  [TRANSLATIONS.SUB_TITLES.STREAMLINED_PROCESS]: 'Streamlined Process',
  [TRANSLATIONS.SUB_TITLES.NEW_WAYS_TO_EARN]: 'New Ways to Earn',
  [TRANSLATIONS.SUB_TITLES.COMING_SOON]: 'Coming Soon!',
  [TRANSLATIONS.SUB_TITLES.I_AM_NOT_AN_AGENT]: 'I am not an Agent',
  [TRANSLATIONS.SUB_TITLES.OTHER_PROPERTIES]: 'Other Properties',
  [TRANSLATIONS.SUB_TITLES.SCAN_TO_CONNECT_WITH_ME]:
    'Connect and explore my unique work on vREfyd.',
  [TRANSLATIONS.SUB_TITLES.QR_LINK_COPIED]:
    'Profile QR link copied to clipboard',

  // paragraphs
  [TRANSLATIONS.PARAGRAPHS.SERVICEABLE_IN_BENGALURU]:
    'We’re currently serviceable in Bengaluru and will be expanding to more cities soon!',
  [TRANSLATIONS.PARAGRAPHS.AIRA_INTRO]:
    "I'm AIRA, India's first AI real estate agent. I find matches, write property descriptions, and share tips to make your job easier.",
  [TRANSLATIONS.PARAGRAPHS.ADD_OTHER_DETAILS_FOR_SETUP]:
    'I’ve got your name and phone number. Just add other details, and I’ll set up your profile in a snap!',
  [TRANSLATIONS.PARAGRAPHS.QUICK_AND_EASY_SETUP]:
    'Quick and easy setup saves you time to focus on growing your business.',
  [TRANSLATIONS.PARAGRAPHS.SHARE_YOUR_TOP_SKILLS]:
    'Share your top skills and strengths. I’ll showcase them to show why you’re the best in real estate.',
  [TRANSLATIONS.PARAGRAPHS.ATTRACT_MORE_LEADS]:
    'Attract more leads by clearly presenting what makes you unique.',
  [TRANSLATIONS.PARAGRAPHS.TELL_ME_LANGUAGE]:
    'Tell me which languages you speak fluently. It helps you reach a broader audience.',
  [TRANSLATIONS.PARAGRAPHS.EXPAND_YOUR_REACH]:
    'Expand your reach beyond local areas and connect with diverse clients.',
  [TRANSLATIONS.PARAGRAPHS.SELECT_THE_CITIES]:
    'Select the cities and areas you operate in. This helps people find you where it matters most.',
  [TRANSLATIONS.PARAGRAPHS.TARGET_LOCAL_MARKET]:
    'Target your local market effectively and increase your business opportunities.',
  [TRANSLATIONS.PARAGRAPHS.AVAILABLE_IN_FIVE_CITIES]:
    "We're currently available in these five cities and look forward to expanding soon",
  [TRANSLATIONS.PARAGRAPHS.ADD_BUSINESS_AND_SOCIAL_MEDIA_DETAILS]:
    'Add your business details and social media links. I’ll integrate them to boost your professional image.',
  [TRANSLATIONS.PARAGRAPHS.ENHANCE_ONLINE_PRESENCE]:
    'Enhance your online presence and save money on branding efforts.',
  [TRANSLATIONS.PARAGRAPHS.RERA_VERIFICATION_NOTE]:
    'At vREfyd, we prioritize your privacy by securely managing all documents and safeguarding your data throughout the RERA verification process',
  [TRANSLATIONS.PARAGRAPHS.DOCUMENT_VERIFICATION_NOTE]:
    'At vREfyd, your privacy is our priority. We ensure all your documents are securely managed, safeguarding your data at every step.',
  [TRANSLATIONS.PARAGRAPHS.VREFYD_AGENT_DETAILS]:
    'Please provide details of a vREfyd Agent',
  [TRANSLATIONS.PARAGRAPHS.COMMUNITY_DETAILS]:
    'Please provide details of your Community',
  [TRANSLATIONS.PARAGRAPHS.PROVIDE_RERA_DETAILS]:
    'Please provide your RERA details',
  [TRANSLATIONS.PARAGRAPHS.TWO_AGENT_REFERENCE]:
    'Please provide references of two Agents who can help us verify that you are an Agent',
  [TRANSLATIONS.PARAGRAPHS.KICK_THINGS_OFF]:
    'Kick things off with a great photo! Upload a professional picture that makes you look approachable and memorable.',
  [TRANSLATIONS.PARAGRAPHS.INSPIRATION_EXAMPLE]:
    'Stand out instantly and attract more interest—no extra marketing needed! See the example below for inspiration.',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_IMAGE_APPEARANCE]:
    'This is how your profile image appears. Upload/Click a quality picture for the best impression',
  [TRANSLATIONS.PARAGRAPHS.ENCRYPT_DATA]:
    'The data you share will be encrypted, stored securely, and only used to verify your identity',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_SHINE]:
    'Let’s get started and make your profile shine! Check out the example below for inspiration.',
  [TRANSLATIONS.PARAGRAPHS.START_ADDING_PROPERTIES]:
    'Start adding properties now to showcase your listings to potential clients.',
  [TRANSLATIONS.PARAGRAPHS.EYE_CATECHING_PROFILE_NOTE]:
    'With my help, you’ll create a professional and eye-catching profile that attracts more leads, saves you money, and helps your real estate business thrive.',
  [TRANSLATIONS.PARAGRAPHS.SIMPLY_TYPE_PROP_DETAILS_FOR_AUTOFILL]:
    'Simply type or share details from your WhatsApp messages, and we will autofill your information effortlessly.',
  [TRANSLATIONS.PARAGRAPHS.PROPERTIES_TRUSTED_COMMUNITY]:
    'Properties, Trusted Community, and AI Tools for Quick, Secure Deals.',
  [TRANSLATIONS.PARAGRAPHS.BETA_VERSION_NOTE]:
    "You're currently using an internal Beta version",
  [TRANSLATIONS.PARAGRAPHS.HEAVY_LOAD_NOTE]:
    "We're facing some heavy load right now and feeling the pressure. Let’s wait a bit, and then you can try again",
  [TRANSLATIONS.PARAGRAPHS.CANNOT_FIND_PAGE]:
    "Sorry, we cant find the page you're looking for",
  [TRANSLATIONS.PARAGRAPHS.DROP_THE_MAP_PIN]:
    'Please drop the pin on the map to confirm your location',
  [TRANSLATIONS.PARAGRAPHS.DEAL_CLOSED_SUCCESSFULLY]:
    'Deal closed successfully',
  [TRANSLATIONS.PARAGRAPHS.NEED_TO_CONFIRM_THE_DEAL_CLOSED_WITH]:
    'Deal closed successfully',
  [TRANSLATIONS.PARAGRAPHS.USER_UNLOCKED_PROPERTY]: 'Deal closed successfully',
  [TRANSLATIONS.PARAGRAPHS.SIT_BACK_AND_RELAX]:
    'Sit back and relax, We will let you know once the property is verified',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_ALREADY_LISTED]:
    'It looks like this property has already been listed. Please check for existing listings before submitting again.',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_VERIFICATION_EXPIRES_IN]:
    'Property Verification Expires in',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_CANNOT_VERIFIED]:
    "We're sorry, your property couldn't be verified.",
  [TRANSLATIONS.PARAGRAPHS.NO_UNLOCK_PROPS_YET]:
    'No unlocked properties yet. Time to explore more leads!',
  [TRANSLATIONS.PARAGRAPHS.NO_PERFECT_MATCH_YET]:
    "No perfect match yet, but don't worry we've alerted our entire network!",
  [TRANSLATIONS.PARAGRAPHS.FIRST_TO_KNOW]:
    'The moment something ideal pops up, you’ll be the first to know.',
  [TRANSLATIONS.PARAGRAPHS.FAILURE_TO_GUIDELINES]:
    '*Failure to follow these guidelines will result in failed  verification.',
  [TRANSLATIONS.PARAGRAPHS.COMPLETE_YOUR_PROFILE_FOR_PROP_VER]:
    'Please complete your profile verification to start the property verification process.',
  [TRANSLATIONS.PARAGRAPHS.VERIFICATION_PENDING_NOTE]:
    "Verification is pending. We'll notify you about the status.",
  [TRANSLATIONS.PARAGRAPHS.VERIFICATION_IN_PROGRESS_NOTE]:
    'Your profile verification is in progress, We will let you know once the profile is verified.',
  [TRANSLATIONS.PARAGRAPHS.QUICK_LOOK_PROFILE]:
    'Take a quick look at your profile before it goes live. Let me know if you want any tweaks',
  [TRANSLATIONS.PARAGRAPHS.ENSURE_PROFILE_PERFECTION]:
    'Ensure your profile looks perfect, saving you from future adjustments.',
  [TRANSLATIONS.PARAGRAPHS.PREFILLED_DETAILS_AIRA]:
    'I’ve pre-filled the details for you based on the information you shared earlier. Take a quick look and fill in any fields that are left out!',
  [TRANSLATIONS.PARAGRAPHS.PROPERTIES_YOU_RE_INTERESTED_IN]:
    "Properties you're interested in are shown here.",
  [TRANSLATIONS.PARAGRAPHS.CHAT_WITH_SOMEONE]: 'It’s nice to chat with someone',
  [TRANSLATIONS.PARAGRAPHS.PICK_A_PERSON]:
    'Pick a person from left menu and start your conversation',
  [TRANSLATIONS.PARAGRAPHS.LEAD_INTERESTED]:
    'Leads interested in your property are shown here.',
  [TRANSLATIONS.PARAGRAPHS.FOUND_A_PROP_MATCH]:
    'I’ve found a property that matches what you’re looking for. Here is the top agent who has listed properties that meet your search criteria',
  [TRANSLATIONS.PARAGRAPHS
    .COULD_NOT_FIND_ANY_PROP]: `I couldn’t find any property that match your search right now, but don’t worry! I’ve already alerted our agent network, and I will notify you immediately when something comes up..`,
  [TRANSLATIONS.PARAGRAPHS.FOUND_SEVERAL_PROP_MATCH]:
    'I’ve found several properties that match what you’re looking for. Here are the',
  [TRANSLATIONS.PARAGRAPHS.WHO_HAVE_LISTED]:
    'who have listed properties that match your search..',
  [TRANSLATIONS.PARAGRAPHS.SHARE_YOUR_PREFERRED_LOCALITY]:
    'Share your preferred locality, budget, or any other details below, and I’ll connect you with the perfect agent in no time!',
  [TRANSLATIONS.PARAGRAPHS.LOOKING_FOR_SOMETHING_SPECIFIC]:
    'Looking for something more specific?',
  [TRANSLATIONS.PARAGRAPHS.BUT_WE_HAVE_ALERTED_OUR_NETWORK]:
    'But we’ve alerted our network. You’ll be notified when the right property comes up!',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PREVIEW_AIRA_TITLE]:
    'Sample Profile – This is how your profile appears',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PREVIEW_AIRA_DESCRIPTION]:
    'With my help, you’ll create a professional and eye-catching profile that attracts more leads, saves you money, and helps your real estate business thrive.',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PREVIEW_AIRA_NOTE]:
    'Let’s get started and make your profile shine! Check out the example below for inspiration.',
  [TRANSLATIONS.PARAGRAPHS.CHECK_VERIFICATION_CODE]:
    'Please check WhatsApp/SMS for verification code',
  [TRANSLATIONS.PARAGRAPHS.LOGIN_SUCCESSFUL]: 'Your Login is Successful',
  [TRANSLATIONS.PARAGRAPHS.TELL_ME_ABOUT_PROPERTY_INVESTMENT]:
    'Tell me about property investment',
  [TRANSLATIONS.PARAGRAPHS.NEED_ADVICE_ON_BUYING]:
    'I need advice on buying a house',
  [TRANSLATIONS.PARAGRAPHS.HOW_CAN_I_EARN_MORE_RENTAL_INCOME]:
    'How can I earn more rental income?',
  [TRANSLATIONS.PARAGRAPHS.WHAT_ARE_THE_BEST_AREAS]:
    'What are the best areas to invest in real estate?',
  [TRANSLATIONS.PARAGRAPHS.BETA_VERSION_FEEDBACK_NOTE]:
    "You're using a Beta version. Help improve AIRA by using Thumbs-Up or Thumbs-Down on responses.",
  [TRANSLATIONS.PARAGRAPHS.HANG_TIGHT]:
    'Hang tight! I’m setting up everything for your property listing',
  [TRANSLATIONS.PARAGRAPHS.ANALYSING_YOUR_INPUTS]:
    'Analysing your inputs to generate the perfect property description',
  [TRANSLATIONS.PARAGRAPHS.OPTIMIZING_LISTING]:
    'Optimizing your listing to attract the right buyers and renters',
  [TRANSLATIONS.PARAGRAPHS.HIGHLIGHTING_FEATURES]:
    'Highlighting key features to make your property stand out',
  [TRANSLATIONS.PARAGRAPHS.ALMOST_VERIFYING_DETAILS]:
    'Almost there! Verifying your details for a polished final touch',
  [TRANSLATIONS.PARAGRAPHS.AT_LEAST_ONE_FILE_REQUIRED]:
    'At least one file is required',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_PINCODE]: 'Enter a valid pincode',
  [TRANSLATIONS.PARAGRAPHS.PINCODE_REQUIRED]: 'Pincode is required',
  [TRANSLATIONS.PARAGRAPHS.PIN_LOCATION]: 'Pin location on Map',
  [TRANSLATIONS.PARAGRAPHS.ROAD_AREA_REQUIRED]: 'Road or area is required',
  [TRANSLATIONS.PARAGRAPHS.LOCALITY_REQUIRED]: 'Locality is required',
  [TRANSLATIONS.PARAGRAPHS.CITY_REQUIRED]: 'City is required',
  [TRANSLATIONS.PARAGRAPHS.STATE_REQUIRED]: 'State is required',
  [TRANSLATIONS.PARAGRAPHS.APARTMENT_REQUIRED]:
    'Apartment or Society name is required',
  [TRANSLATIONS.PARAGRAPHS.HOUSE_NO_REQUIRED]: 'House number is required',
  [TRANSLATIONS.PARAGRAPHS.BUILT_UP_AREA_MUST_BE_A_NUMBER]:
    'Built-up area must be a number',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_BUILT_UP_AREA]:
    "Enter valid built-up area'",
  [TRANSLATIONS.PARAGRAPHS.CARPET_AREA_MUST_BE_A_NUMBER]:
    'Carpet area must be a number',
  [TRANSLATIONS.PARAGRAPHS.CARPET_AREA_MUST_BE_SMALLER_THAN_BUILT_UP_AREA]:
    'Carpet area must be smaller than built-up area',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_CARPET_AREA]: 'Enter valid carpet area',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_INPUT]: 'Enter valid input',
  [TRANSLATIONS.PARAGRAPHS.TOTAL_FLOORS_ARE_REQUIRED]:
    'Total floors are required',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_NUMBER_OF_FLOORS]:
    'Enter valid number of floors',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_ON_FLOOR_REQUIRED]:
    'Property on floor is required',
  [TRANSLATIONS.PARAGRAPHS.PROPERTY_ON_FLOOR_MUST_BE_LESSER_THAN_TOTAL_FLOORS]:
    'Property on floor must be lesser than total floors',
  [TRANSLATIONS.PARAGRAPHS.LOCK_IN_PERIOD_IS_REQUIRED]:
    'Lock-in period is required',
  [TRANSLATIONS.PARAGRAPHS.MAINTENANCE_AMOUNT_REQUIRED]:
    'Maintenance amount is required for Rent listings',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_MAINTENANCE_AMOUNT]:
    'Please enter a valid maintenance amount',
  [TRANSLATIONS.PARAGRAPHS.SECURITY_DEPOSIT_REQUIRED]:
    'Security deposit is required for Rent listings',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_SECURITY_DEPOSIT_AMOUNT]:
    'Please enter a valid security deposit amount',
  [TRANSLATIONS.PARAGRAPHS.RENT_AMOUNT_REQUIRED]:
    'Rent amount is required for Rent listings',
  [TRANSLATIONS.PARAGRAPHS.ENTER_VALID_RENT_AMOUNT]:
    'Please enter a valid rent amount',
  [TRANSLATIONS.PARAGRAPHS.SALE_PRICE_REQUIRED]:
    "Sale price is required for 'For Sale' listings",
  [TRANSLATIONS.PARAGRAPHS.LEAD_INTERESTED]:
    'Leads interested in your property are shown here.',
  [TRANSLATIONS.PARAGRAPHS.NUMBER_ASSOCIATED_WITH_AGENT]:
    'Success! This number is associated with a vREfyd agent',
  [TRANSLATIONS.PARAGRAPHS.NUMBER_NOT_ASSOCIATED_WITH_AGENT]:
    'Sorry, we couldn’t verify this phone number. Ensure it’s correct or try again later.',
  [TRANSLATIONS.PARAGRAPHS.GAIN_TRUST_AND_CREDIBILITY]:
    'Gain trust and credibility, making it easier to attract more leads.',
  [TRANSLATIONS.PARAGRAPHS.THIS_IS_THE_FINAL_STEP]:
    'This is the final step! Choose a verification method, get your purple tick, and start growing your business!',
  [TRANSLATIONS.PARAGRAPHS.GET_VERIFIED_WITH_EASE]: 'Get Verified with Ease',
  [TRANSLATIONS.PARAGRAPHS.CHATTING_WITH_UNVERIFIED_AGENT]:
    "The person you're chatting with may be an unverified agent or client. Please proceed with caution and ensure secure communication.",
  [TRANSLATIONS.PARAGRAPHS.SHOWCASE_YOUR_EXPERTISE]:
    'Showcase your expertise and stand out as a vREfyd agent!',
  [TRANSLATIONS.PARAGRAPHS
    .YOUR_VERIFICATION_IS_PENDING]: `Your profile verification is pending. We'll notify you when there's an update.`,
  [TRANSLATIONS.PARAGRAPHS.YOUR_VERIFICATION_WAS_NOT_APPROVED]:
    'Your profile verification was not approved. Kindly update the required details and resubmit to get verified.',
  [TRANSLATIONS.PARAGRAPHS.BUILD_YOUR_OWN_DIGITAL_SHOP]:
    'Build Your own Digital Shop',
  [TRANSLATIONS.PARAGRAPHS.DIRECT_TENANT_BUYER_LEADS]:
    'Direct tenant, buyer leads]: close deals smoothly',
  [TRANSLATIONS.PARAGRAPHS.SHOW_PROPERTY_POTENTIAL]:
    'Show property potential, boost closure success rate.',
  [TRANSLATIONS.PARAGRAPHS.STREAMLINE_DEALS_WITH_QUICK]:
    'Streamline deals with quick, hassle-free agreement execution.',
  [TRANSLATIONS.PARAGRAPHS.OFFER_LOANS_MORTGAGES]:
    'Offer loans, mortgages, deposits with a click',
  [TRANSLATIONS.PARAGRAPHS.EXCLUSIVE_OWNER_BUILDER_LISTINGS]:
    'Exclusive owner, builder listings]: close deals quickly',
  [TRANSLATIONS.PARAGRAPHS.ONLY_VERIFIED_REALTORS_CAN_ADD]:
    'Only verified realtors can add properties to ',
  [TRANSLATIONS.PARAGRAPHS.COMPLETE_THE_VERIFICATION_PROCESS]:
    'Complete the verification process to ensure genuine listings and maintain trust.',
  [TRANSLATIONS.PARAGRAPHS.LIST_UNLIMITED_PROPS]:
    'List unlimited properties at no extra cost',
  [TRANSLATIONS.PARAGRAPHS.CLOSE_MORE_DEALS]:
    'Close more deals with less effort',
  [TRANSLATIONS.PARAGRAPHS.CONTROL_YOUR_DATA]:
    'Control your data, always secure and private',
  [TRANSLATIONS.PARAGRAPHS.KEEP_EVERY_RUPEE]:
    'Keep every rupee of your brokerage earnings',
  [TRANSLATIONS.PARAGRAPHS.REACH_BEYOND_YOUR_CITY]:
    'Reach beyond your city for more buyers',
  [TRANSLATIONS.PARAGRAPHS.MATCH_YOUR_LISTINGS]:
    'Match your listings to the right audience',
  [TRANSLATIONS.PARAGRAPHS.SKIP_MESSY_GROUPS]:
    'Skip messy groups — manage all connections in one place.',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_PICTURE_UPDATED_SUCCESSFULLY]:
    'Profile picture updated successfully',
};

export default translationEnglish;
