import { TRANSLATIONS } from '../translations';

const translationBangla = {
  // titles
  [TRANSLATIONS.TITLES.HOME]: 'হোম',
  [TRANSLATIONS.TITLES.PROPERTIES]: 'সম্পত্তি',
  [TRANSLATIONS.TITLES.MESSAGES]: 'বার্তা',
  [TRANSLATIONS.TITLES.AGENT_ID]: 'এজেন্ট আইডি',
  [TRANSLATIONS.TITLES.LOGOUT]: 'লগআউট',
  [TRANSLATIONS.TITLES.PROFILE]: 'প্রোফাইল',
  [TRANSLATIONS.TITLES.ASK_AIRA]: 'AIRA-কে জিজ্ঞাসা করুন',
  [TRANSLATIONS.TITLES.SAVED]: 'সংরক্ষিত',
  [TRANSLATIONS.TITLES.UNLOCKED]: 'আনলকড',
  [TRANSLATIONS.TITLES.MY_LISTINGS]: 'আমার তালিকা',
  [TRANSLATIONS.TITLES.LANGUAGE]: 'ভাষা',
  [TRANSLATIONS.TITLES.MIN_BUILD_UP]: 'সর্বনিম্ন নির্মাণ ক্ষেত্রফল',
  [TRANSLATIONS.TITLES.MAX_BUILD_UP]: 'সর্বোচ্চ নির্মাণ ক্ষেত্রফল',
  [TRANSLATIONS.TITLES.FILTERS]: 'ফিল্টার(গুলি)',
  [TRANSLATIONS.TITLES.FILTER]: 'ফিল্টার',
  [TRANSLATIONS.TITLES.AMENITIES]: 'সুবিধাসমূহ',
  [TRANSLATIONS.TITLES.NOTIFICATIONS]: 'নোটিফিকেশন',
  [TRANSLATIONS.TITLES.BEDROOM]: 'শয়নকক্ষ',
  [TRANSLATIONS.TITLES.BEDROOMS]: 'শয়নকক্ষসমূহ',
  [TRANSLATIONS.TITLES.PROPERTY_TYPE]: 'সম্পত্তির ধরন',
  [TRANSLATIONS.TITLES.BATHROOMS]: 'বাথরুমসমূহ',
  [TRANSLATIONS.TITLES.MESSAGE]: 'বার্তা',
  [TRANSLATIONS.TITLES.NAME]: 'নাম',
  [TRANSLATIONS.TITLES.PHONE_NUMBER]: 'ফোন নম্বর',
  [TRANSLATIONS.TITLES.EMAIL]: 'ইমেইল',
  [TRANSLATIONS.TITLES.LANGUAGE_S]: 'ভাষা(গুলি)',
  [TRANSLATIONS.TITLES.REMOVE]: 'অপসারণ করুন',
  [TRANSLATIONS.TITLES.ADDRESS]: 'ঠিকানা',
  [TRANSLATIONS.TITLES.AGENT]: 'এজেন্ট',
  [TRANSLATIONS.TITLES.AREA]: 'এলাকা',
  [TRANSLATIONS.TITLES.VERIFY]: 'যাচাই করুন',
  [TRANSLATIONS.TITLES.PROCEED]: 'অগ্রসর হন',
  [TRANSLATIONS.TITLES.STATE]: 'রাজ্য',
  [TRANSLATIONS.TITLES.PINCODE]: 'পিনকোড',
  [TRANSLATIONS.TITLES.SELECT]: 'নির্বাচন করুন',
  [TRANSLATIONS.TITLES.RERA_NUMBER]: 'RERA নম্বর',
  [TRANSLATIONS.TITLES.ADD_MORE_DETAILS]: 'আরও বিস্তারিত যোগ করুন',
  [TRANSLATIONS.TITLES.RETAKE_REUPLOAD_PHOTO]:
    'পুনরায় তুলুন / পুনরায় আপলোড করুন',
  [TRANSLATIONS.TITLES.CLOSED_DEALS]: 'বন্ধ চুক্তিসমূহ',
  [TRANSLATIONS.TITLES.ACTIVE_LISTING]: 'সক্রিয় তালিকা',
  [TRANSLATIONS.TITLES.VERIFICATION]: 'যাচাইকরণ',
  [TRANSLATIONS.TITLES.ABOUT]: 'সম্পর্কিত',
  [TRANSLATIONS.TITLES.CLOSED]: 'বন্ধ',
  [TRANSLATIONS.TITLES.AVAILABILITY]: 'উপলব্ধতা',
  [TRANSLATIONS.TITLES.TRY_AGAIN]: 'পুনরায় চেষ্টা করুন',
  [TRANSLATIONS.TITLES.BACK_TO_HOME]: 'হোমে ফিরে যান',
  [TRANSLATIONS.TITLES.DEAL_CLOSURE]: 'চুক্তি সম্পন্ন করা',

  // subtitles
  [TRANSLATIONS.SUB_TITLES.PASTE_PROPERTY_DETAILS_HERE]:
    'এখানে আপনার সম্পত্তির বিবরণ পেস্ট করুন',
  [TRANSLATIONS.SUB_TITLES.AUTO_FILL_WITH]: 'স্বয়ংক্রিয়ভাবে পূরণ করুন',
  [TRANSLATIONS.SUB_TITLES.FILTER_S_ADDED]: 'ফিল্টার(গুলি) যোগ করা হয়েছে',
  [TRANSLATIONS.SUB_TITLES.ADD_A_PROPERTY]: 'একটি সম্পত্তি যোগ করুন',
  [TRANSLATIONS.SUB_TITLES.CONNECT_SMARTER]: 'আরো স্মার্টভাবে সংযোগ করুন',
  [TRANSLATIONS.SUB_TITLES.CLOSE_FASTER]: 'vREfyd এর মাধ্যমে দ্রুত চুক্তি করুন',

  // paragraphs
  [TRANSLATIONS.PARAGRAPHS.SERVICEABLE_IN_BENGALURU]:
    'আমরা বর্তমানে শুধুমাত্র বেঙ্গালুরুতে পরিষেবা দিচ্ছি এবং শীঘ্রই আরও শহরে আসছি!',
  [TRANSLATIONS.PARAGRAPHS.AIRA_INTRO]:
    'আমি AIRA, ভারতের প্রথম এআই রিয়েল এস্টেট এজেন্ট। আমি উপযুক্ত মিল খুঁজে দিই, সম্পত্তির বিবরণ লিখি এবং আপনার কাজ সহজ করার জন্য সুপারিশ প্রদান করি।',
  [TRANSLATIONS.PARAGRAPHS.ADD_OTHER_DETAILS_FOR_SETUP]:
    'আমি আপনার নাম এবং ফোন নম্বর পেয়েছি। এখন শুধু কিছু অতিরিক্ত বিবরণ যোগ করুন এবং আমি সঙ্গে সঙ্গে আপনার প্রোফাইল সেটআপ করব!',
  [TRANSLATIONS.PARAGRAPHS.QUICK_AND_EASY_SETUP]:
    'দ্রুত এবং সহজ সেটআপ আপনাকে সময় বাঁচাতে সাহায্য করবে যাতে আপনি আপনার ব্যবসার বৃদ্ধি করতে পারেন।',
  [TRANSLATIONS.PARAGRAPHS.SHARE_YOUR_TOP_SKILLS]:
    'আপনার শীর্ষ দক্ষতা এবং বিশেষীকরণ শেয়ার করুন। আমি সেগুলো এমনভাবে প্রদর্শন করব যাতে মানুষ বুঝতে পারে কেন আপনি রিয়েল এস্টেটের সেরা।',
  [TRANSLATIONS.PARAGRAPHS.ATTRACT_MORE_LEADS]:
    'আপনার দক্ষতাগুলি স্পষ্টভাবে উপস্থাপন করে আরও লিড আকর্ষণ করুন।',
  [TRANSLATIONS.PARAGRAPHS.TELL_ME_LANGUAGE]:
    'আমাকে বলুন আপনি কোন কোন ভাষায় পারদর্শী। এটি আপনাকে আরও বিস্তৃত দর্শকদের কাছে পৌঁছাতে সাহায্য করবে।',
  [TRANSLATIONS.PARAGRAPHS.EXPAND_YOUR_REACH]:
    'স্থানীয় এলাকার বাইরে আপনার পৌঁছানোর পরিধি প্রসারিত করুন এবং বিভিন্ন গ্রাহকের সাথে সংযুক্ত হন।',
  [TRANSLATIONS.PARAGRAPHS.SELECT_THE_CITIES]:
    'সেই শহর এবং এলাকাগুলো নির্বাচন করুন যেখানে আপনি কাজ করেন। এটি লোকেদের সঠিক জায়গায় আপনাকে খুঁজে পেতে সাহায্য করবে।',
  [TRANSLATIONS.PARAGRAPHS.TARGET_LOCAL_MARKET]:
    'আপনার স্থানীয় বাজারকে কার্যকরভাবে লক্ষ্য করুন এবং আপনার ব্যবসায়িক সুযোগ বৃদ্ধি করুন।',
  [TRANSLATIONS.PARAGRAPHS.AVAILABLE_IN_FIVE_CITIES]:
    'আমরা বর্তমানে এই পাঁচটি শহরে উপলব্ধ এবং শীঘ্রই প্রসারিত হওয়ার আশা রাখি।',
  [TRANSLATIONS.PARAGRAPHS.ADD_BUSINESS_AND_SOCIAL_MEDIA_DETAILS]:
    'আপনার ব্যবসার বিবরণ এবং সোশ্যাল মিডিয়া লিঙ্ক যোগ করুন। আমি সেগুলোকে আপনার পেশাদার ইমেজ বৃদ্ধি করতে সংযুক্ত করব।',
  [TRANSLATIONS.PARAGRAPHS.ENHANCE_ONLINE_PRESENCE]:
    'আপনার অনলাইন উপস্থিতি উন্নত করুন এবং ব্র্যান্ডিং প্রচেষ্টায় অর্থ সাশ্রয় করুন।',
  [TRANSLATIONS.PARAGRAPHS.RERA_VERIFICATION_NOTE]:
    'vREfyd-এ, আমরা আপনার গোপনীয়তাকে অগ্রাধিকার দিই, সমস্ত ডকুমেন্ট নিরাপদে পরিচালনা করে এবং RERA যাচাইকরণ প্রক্রিয়া জুড়ে আপনার ডেটা সুরক্ষিত রাখি।',
  [TRANSLATIONS.PARAGRAPHS.DOCUMENT_VERIFICATION_NOTE]:
    'vREfyd-এ, আপনার গোপনীয়তা আমাদের অগ্রাধিকার। আমরা নিশ্চিত করি যে আপনার সমস্ত ডকুমেন্ট নিরাপদে পরিচালিত হয়, প্রতিটি ধাপে আপনার ডেটা সুরক্ষিত রাখি।',
  [TRANSLATIONS.PARAGRAPHS.VREFYD_AGENT_DETAILS]:
    'একজন vREfyd এজেন্টের বিবরণ প্রদান করুন।',
  [TRANSLATIONS.PARAGRAPHS.COMMUNITY_DETAILS]:
    'আপনার সম্প্রদায়ের বিবরণ প্রদান করুন।',
  [TRANSLATIONS.PARAGRAPHS.PROVIDE_RERA_DETAILS]:
    'আপনার RERA বিবরণ প্রদান করুন।',
  [TRANSLATIONS.PARAGRAPHS.TWO_AGENT_REFERENCE]:
    'দুইজন এজেন্টের রেফারেন্স প্রদান করুন যারা আমাদেরকে যাচাই করতে সাহায্য করতে পারে যে আপনি একজন এজেন্ট।',
  [TRANSLATIONS.PARAGRAPHS.KICK_THINGS_OFF]:
    'একটি দুর্দান্ত ছবি দিয়ে শুরু করুন! একটি পেশাদার ছবি আপলোড করুন যা আপনাকে সহজে যোগাযোগযোগ্য এবং স্মরণীয় করে তোলে।',
  [TRANSLATIONS.PARAGRAPHS.INSPIRATION_EXAMPLE]:
    'তাৎক্ষণিকভাবে আলাদা হোন এবং আরও আগ্রহ আকর্ষণ করুন—কোনো অতিরিক্ত মার্কেটিং প্রয়োজন নেই! অনুপ্রেরণার জন্য নীচের উদাহরণটি দেখুন।',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_IMAGE_APPEARANCE]:
    'এটি আপনার প্রোফাইল ছবি কিভাবে প্রদর্শিত হয়। সেরা ইমপ্রেশনের জন্য একটি মানসম্পন্ন ছবি আপলোড/ক্লিক করুন।',
  [TRANSLATIONS.PARAGRAPHS.ENCRYPT_DATA]:
    'আপনার শেয়ার করা ডেটা এনক্রিপ্ট করা হবে, নিরাপদে সংরক্ষিত হবে এবং শুধুমাত্র আপনার পরিচয় যাচাই করতে ব্যবহৃত হবে।',
  [TRANSLATIONS.PARAGRAPHS.PROFILE_SHINE]:
    'চলুন শুরু করি এবং আপনার প্রোফাইলকে উজ্জ্বল করুন! অনুপ্রেরণার জন্য নীচের উদাহরণটি দেখুন।',
  [TRANSLATIONS.PARAGRAPHS.START_ADDING_PROPERTIES]:
    'সম্ভাব্য ক্লায়েন্টদের কাছে আপনার তালিকাগুলো প্রদর্শন করতে এখনই সম্পত্তি যোগ করা শুরু করুন।',
  [TRANSLATIONS.PARAGRAPHS.EYE_CATECHING_PROFILE_NOTE]:
    'আমার সাহায্যে, আপনি একটি পেশাদার এবং আকর্ষণীয় প্রোফাইল তৈরি করবেন যা আরও লিড আকর্ষণ করে, আপনার অর্থ সাশ্রয় করে এবং আপনার রিয়েল এস্টেট ব্যবসাকে উন্নত করে।',
  [TRANSLATIONS.PARAGRAPHS.SIMPLY_TYPE_PROP_DETAILS_FOR_AUTOFILL]:
    'কেবল টাইপ করুন বা আপনার WhatsApp মেসেজ থেকে বিবরণ শেয়ার করুন, এবং আমরা আপনার তথ্য সহজেই স্বয়ংক্রিয়ভাবে পূরণ করব।',
  [TRANSLATIONS.PARAGRAPHS.PROPERTIES_TRUSTED_COMMUNITY]:
    'সম্পত্তি, বিশ্বস্ত সম্প্রদায় এবং দ্রুত, নিরাপদ লেনদেনের জন্য AI টুলস।',
  [TRANSLATIONS.PARAGRAPHS.BETA_VERSION_NOTE]:
    'আপনি বর্তমানে একটি অভ্যন্তরীণ Beta সংস্করণ ব্যবহার করছেন।',
  [TRANSLATIONS.PARAGRAPHS.HEAVY_LOAD_NOTE]:
    'আমরা বর্তমানে কিছু ভারী লোডের সম্মুখীন হচ্ছি এবং চাপ অনুভব করছি। একটু অপেক্ষা করুন, এবং তারপর আপনি আবার চেষ্টা করতে পারেন।',
  [TRANSLATIONS.PARAGRAPHS.CANNOT_FIND_PAGE]:
    'দুঃখিত, আমরা আপনি যে পৃষ্ঠাটি খুঁজছেন তা খুঁজে পাচ্ছি না।',
  [TRANSLATIONS.PARAGRAPHS.DROP_THE_MAP_PIN]:
    'আপনার অবস্থান নিশ্চিত করতে মানচিত্রে পিন ড্রপ করুন।',
  [TRANSLATIONS.PARAGRAPHS.DEAL_CLOSED_SUCCESSFULLY]:
    'লেনদেন সফলভাবে সম্পন্ন হয়েছে।',
  [TRANSLATIONS.PARAGRAPHS.NEED_TO_CONFIRM_THE_DEAL_CLOSED_WITH]:
    'তাদেরকে অবহিত করা হবে এবং তাদের সাথে এই লেনদেনটি সম্পন্ন হয়েছে তা নিশ্চিত করতে হবে।',
  [TRANSLATIONS.PARAGRAPHS.USER_UNLOCKED_PROPERTY]:
    'এই ব্যবহারকারীরা এই সম্পত্তিটি আনলক করেছেন।',
};

export default translationBangla;
