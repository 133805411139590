import { Avatar, Box, Divider, Typography } from '@mui/material';
import React from 'react';
import vREFYD_PURPLE from '../../../Assets/images/vREFYD_purple.png';
import SUCCESSTICK from '../../../Assets/images/success-tick.png';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import KingBedOutlinedIcon from '@mui/icons-material/KingBedOutlined';
import ShowerOutlinedIcon from '@mui/icons-material/ShowerOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import SquareFootOutlinedIcon from '@mui/icons-material/SquareFootOutlined';
import { QRCode } from 'react-qrcode-logo';
import ImagesLinks from '../../../Utils/ImagesLinks';
import { calculateAge, numDifferentiation } from '../../../Utils/helperUtils';
import { formatMessage } from '../../../constants/enums';

const Flyer2 = ({ selectedproperty, coverImage }) => {
  return (
    <Box
      id="flyer2"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '480px',
        height: '621px',
        margin: '10px',
        position: 'relative',
        overflow: 'hidden',
        background: '#FFFFFF',
        padding: '20px',
      }}
    >
      <Typography
        sx={{
          display: 'flex',
          marginRight: '10px',
          justifyContent: 'flex-end',
          alignItems: 'center',
          color: '#7D7D7D',
          fontSize: '12px',
        }}
      >
        Property posted on
        <img src={vREFYD_PURPLE} width={55} alt="vREfyd" />
      </Typography>

      <Typography
        sx={{
          color: '#474747',
          fontSize: '16px',
          fontWeight: 600,
          my: 1,
        }}
      >
        {selectedproperty?.property_title}
      </Typography>
      <Typography
        sx={{
          color: '#7D7D7D',
          fontSize: '14px',
          fontWeight: 400,
          lineHeight: '24px',
          my: '25px',
        }}
        dangerouslySetInnerHTML={{
          __html: formatMessage(
            selectedproperty?.property_short_description ||
              selectedproperty?.property_description?.length > 100
              ? selectedproperty?.property_description?.slice(0, 100) + '...'
              : selectedproperty?.property_description
          ),
        }}
      ></Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '25%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <SquareFootOutlinedIcon
            sx={{
              color: '#600DB4',
              width: '30px',
              height: '30px',
              strokeWidth: 0.5,
            }}
          />
          <Typography
            sx={{
              color: '#474747',
              fontSize: '14px',
            }}
          >
            {selectedproperty?.built_up_area} Sq. Ft.
          </Typography>
        </Box>
        {selectedproperty?.bathroom_count && (
          <Box
            sx={{
              width: '25%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <KingBedOutlinedIcon
              sx={{
                color: '#600DB4',
                width: '30px',
                height: '30px',
              }}
            />
            <Typography
              sx={{
                color: '#474747',
                fontSize: '14px',
              }}
            >
              {selectedproperty?.bathroom_count} Bedrooms
            </Typography>
          </Box>
        )}
        {selectedproperty?.bedroom_count && (
          <Box
            sx={{
              width: '25%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <ShowerOutlinedIcon
              sx={{
                color: '#600DB4',
                width: '30px',
                height: '30px',
              }}
            />
            <Typography
              sx={{
                color: '#474747',
                fontSize: '14px',
              }}
            >
              {selectedproperty?.bedroom_count} bathrooms
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            width: '25%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <HomeOutlinedIcon
            sx={{
              color: '#600DB4',
              width: '30px',
              height: '30px',
            }}
          />
          <Typography
            sx={{
              color: '#474747',
              fontSize: '14px',
            }}
          >
            {calculateAge(selectedproperty?.property_established)} Years Old
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 2,
        }}
      >
        {selectedproperty?.files?.slice(0, 4).map(
          (file) =>
            file?.file_type === 'image' && (
              <Box
                sx={{
                  minWidth: '100px',
                  maxWidth: '155px',
                  height: '100px',
                  m: '5px',
                }}
              >
                <img
                  src={file.file_url}
                  style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                  alt="img"
                />
              </Box>
            )
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          sx={{ color: '#7D7D7D', fontWeight: 500, fontSize: '16px' }}
        >
          For {selectedproperty?.property_sale_type}
        </Typography>
        <Typography
          sx={{ color: '#600DB4', fontWeight: 600, fontSize: '34px', mx: 2 }}
        >
          {' '}
          {selectedproperty?.sale_price
            ? numDifferentiation(selectedproperty?.sale_price)
            : numDifferentiation(selectedproperty?.rent_amount)}
        </Typography>
        {selectedproperty?.property_sale_type === 'Rent' && (
          <Typography
            sx={{ color: '#7D7D7D', fontWeight: 500, fontSize: '16px' }}
          >
            Per Month
          </Typography>
        )}
      </Box>
      <Divider sx={{ opacity: 0.5, my: 1 }} />
      <Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Avatar src={selectedproperty?.profile_picture} sx={{ mr: 2 }} />
            <Box sx={{ display: 'flex', flexDirection: 'column' }}>
              <Typography sx={{ fontSize: '10px', color: '#7D7D7D' }}>
                Listed by
              </Typography>
              <Typography
                sx={{
                  color: '#474747',
                  fontSize: '14px',
                  fontWeight: 600,
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                {selectedproperty?.created_by}

                {selectedproperty?.is_verified && (
                  <img
                    src={SUCCESSTICK}
                    alt="profile"
                    className="agent-verified-icon"
                    style={{ zIndex: 1 }}
                  />
                )}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ width: '100px', height: '100px' }}>
            <Box
              sx={{
                border: '1px solid #FEFEFE',
                borderRadius: '20px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '100%',
                height: '100%',
              }}
            >
              <QRCode
                value={selectedproperty?.qr_code}
                fgColor="#6A1B9A"
                qrStyle="dots"
                logoImage={ImagesLinks.vrefydQrLogo}
                removeQrCodeBehindLogo={true}
                ecLevel="L"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '12px',
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            position: 'absolute',
            bottom: 10,
            left: 20,
          }}
        >
          <Typography sx={{ fontSize: '12px', color: '#7D7D7D' }}>
            Explore my unique work on
          </Typography>
          <Typography
            sx={{
              color: '#590DB4',
              fontSize: '12px',
              background: '#590DB426',
              width: 'max-content',
              padding: '4px',
              borderRadius: '8px',
            }}
          >
            {selectedproperty?.qr_code}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Flyer2;
