import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
  Avatar,
  Button,
  Card,
  CardContent,
  Chip,
  Typography,
} from '@mui/material';
import { Download, Share } from '@mui/icons-material';
import ImagesLinks from '../../../../Utils/ImagesLinks';
import { QRCode } from 'react-qrcode-logo';
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import { useSelector } from 'react-redux';
import {
  IconBuilding,
  IconRosetteDiscountCheckFilled,
} from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { TRANSLATIONS } from '../../../../Utils/translations';

const specializationCategory = {
  'Residential Sales': { backgroundColor: '#F3EBFB', color: '#67399D' },
  'Residential Rent': {
    backgroundColor: 'rgba(255, 164, 37, 0.2)',
    color: '#FFA425',
  },
  'Residential Leasing': {
    backgroundColor: 'rgba(230, 244, 249, 1)',
    color: '#16C2FF',
  },
  'Commercial Sales': { backgroundColor: '#E2EBFA', color: '#336CAB' },
  'Commercial Rent': { backgroundColor: '#F4F0DF', color: '#A38D2D' },
};

export default function GenerateQRModal({ open, setOpen, agentDetails }) {
  const { t } = useTranslation();

  const areasOfOperation = JSON.parse(
    JSON.parse(agentDetails?.area_of_operation || null)
  );

  const handleQRDownload = async () => {
    const element = document.getElementById('downloadQRImage');
    const canvas = await html2canvas(element, { useCORS: true });
    const data = canvas.toDataURL('image/png', 1);
    downloadBase64Image(data);
  };

  const downloadBase64Image = (base64Image, fileName) => {
    const link = document.createElement('a');
    link.href = base64Image;
    link.download = `${agentDetails?.full_name || 'Agent'}-digital-card.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Card
        sx={{
          borderRadius: '16px',
          width: { lg: '540px', sm: '540px', xs: '96%' },
          maxHeight: '90%',
          overflowY: 'scroll',
          '& .MuiCardContent-root': {
            padding: '0 !important',
          },
        }}
      >
        <CardContent id="downloadQRImage">
          <Box sx={{ padding: { lg: '1.5rem', sm: '1.5rem', xs: '1rem' } }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {/* avatar */}
              <Avatar
                src={agentDetails?.profile_picture}
                alt="User"
                sx={{
                  width: { lg: 144, sm: 144, xs: 120 },
                  height: '100%',
                  border: '4px solid white',
                  borderRadius: '24px',
                }}
              />

              {/* name and company */}
              <Box
                sx={{
                  marginLeft: '1rem',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 900,
                    display: 'flex',
                    fontSize: { lg: '32px', sm: '28px', xs: '24px' },
                    alignItems: 'center',
                  }}
                >
                  {agentDetails?.full_name}{' '}
                  {agentDetails?.is_verified && (
                    <Box
                      sx={{
                        marginLeft: '5px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                    >
                      <IconRosetteDiscountCheckFilled color="#600DB4" />
                    </Box>
                  )}
                </Typography>

                {agentDetails?.brand_name && (
                  <Box
                    sx={{ display: 'flex', alignItems: 'center', columnGap: 1 }}
                  >
                    <Box
                      sx={{
                        background: 'rgba(255, 164, 37, 0.2)',
                        width: '32px',
                        height: '32px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: '#83D265',
                        borderRadius: '6px',
                      }}
                    >
                      <IconBuilding size={16} color="#FFA425" />
                    </Box>
                    <Typography sx={{ color: '#7D7D7D', fontSize: '14px' }}>
                      {agentDetails?.brand_name}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            {/* areas of operation */}
            {areasOfOperation?.length > 0 && (
              <Box sx={{ marginTop: '2rem' }}>
                <Typography
                  sx={{
                    fontSize: '16px',
                    fontWeight: 600,
                    color: '#7D7D7D',
                    marginBottom: '1rem',
                  }}
                >
                  {t(TRANSLATIONS.SUB_TITLES.AREAS_OF_OPERATION)}
                </Typography>
                {areasOfOperation?.map((area) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      flexWrap: 'wrap',
                      columnGap: 1,
                      marginLeft: { lg: '1rem', xs: '.1rem' },
                    }}
                  >
                    {area?.places?.map((area, index) => (
                      <Chip
                        key={area}
                        label={area}
                        sx={{
                          color: '#474747',
                          marginBottom: '.5rem',
                          borderRadius: '16px',
                          background: '#E3E3E3',
                          fontSize: '12px',
                          padding: '4px',
                        }}
                      />
                    ))}
                  </Box>
                ))}
              </Box>
            )}

            {/* specialization */}
            {agentDetails?.specialization?.length > 0 && (
              <Box sx={{ marginTop: '2rem' }}>
                <Typography
                  sx={{ fontSize: '16px', fontWeight: 600, color: '#7D7D7D' }}
                >
                  {t(TRANSLATIONS.TITLES.SPECIALIZATION)}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '1rem',
                    flexWrap: 'wrap',
                    marginLeft: { lg: '1rem', xs: '.1rem' },
                    gap: 1,
                  }}
                >
                  {agentDetails?.specialization?.map((specialty, index) => (
                    <Box sx={{ marginBottom: '4px' }} key={specialty}>
                      <Typography
                        sx={{
                          color: specializationCategory[specialty]?.color,
                          fontSize: '13px',
                          fontWeight: '500',
                          backgroundColor:
                            specializationCategory[specialty]?.backgroundColor,
                          padding: '6px 12px',
                          borderRadius: '16px',
                        }}
                      >
                        {specialty}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Box>
            )}

            {/* additional services */}
            {agentDetails?.other_services?.length > 0 && (
              <Box sx={{ marginTop: '2rem' }}>
                <Typography
                  sx={{ fontSize: '15px', fontWeight: 600, color: '#7D7D7D' }}
                >
                  {t(TRANSLATIONS.TITLES.ADDITIONAL_SERVICES)}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    marginTop: '1rem',
                    flexWrap: 'wrap',
                    marginLeft: { lg: '1rem', xs: '.1rem' },
                    gap: 1,
                  }}
                >
                  <Typography
                    sx={{
                      color: '#7D7D7D',
                      fontSize: '13px',
                      fontWeight: '500',
                      padding: '12px',
                      borderRadius: '16px',
                      lineHeight: '2',
                    }}
                  >
                    {agentDetails?.other_services?.join('  |  ')}
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              padding: { lg: '2rem', xs: '1rem' },
              display: 'flex',
              gap: 1,
              flexDirection: { lg: 'row', sm: 'row', xs: 'column-reverse' },
              justifyContent: 'space-between',
              alignItems: 'center',
              backgroundColor: '#6913C8',
              width: '100%',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
              }}
            >
              <Box sx={{ textAlign: { lg: 'left', sm: 'left', xs: 'center' } }}>
                <img
                  src={ImagesLinks.vrefydLogoWhite}
                  alt="vREfyd Logo"
                  style={{ width: '160px' }}
                />

                <Typography
                  sx={{
                    color: '#fff',
                    fontSize: '12px',
                    marginLeft: '8px',
                    marginTop: '.5rem',
                  }}
                >
                  {t(TRANSLATIONS.SUB_TITLES.SCAN_TO_CONNECT_WITH_ME)}
                </Typography>
              </Box>

              {/* Action Buttons */}
              {agentDetails?.qr_code && (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    gap: 1,
                    marginTop: '2rem',
                  }}
                  data-html2canvas-ignore="true"
                >
                  <Button
                    variant="outlined"
                    startIcon={<Share />}
                    onClick={() => {
                      navigator.clipboard.writeText(agentDetails?.qr_code);
                      toast.success(t(TRANSLATIONS.SUB_TITLES.QR_LINK_COPIED));
                    }}
                    sx={{
                      borderRadius: '8px',
                      textTransform: 'none',
                      color: '#fff',
                      borderColor: '#fff',
                      '&:hover': {
                        borderColor: '#ffffe2',
                      },
                    }}
                  >
                    {t(TRANSLATIONS.TITLES.SHARE_CARD)}
                  </Button>
                  <Button
                    variant="contained"
                    onClick={handleQRDownload}
                    startIcon={<Download />}
                    sx={{
                      backgroundColor: '#fff',
                      color: '#6913C8',
                      borderRadius: '8px',
                      textTransform: 'none',
                      width: '45%',
                      lineHeight: '1.5',
                      '&:hover': {
                        backgroundColor: '#ffffe1',
                      },
                    }}
                  >
                    {t(TRANSLATIONS.TITLES.DOWNLOAD)}
                  </Button>
                </Box>
              )}
            </Box>

            {/* QR Code */}
            {agentDetails?.qr_code && (
              <Box
                sx={{
                  border: '1px solid #FEFEFE',
                  padding: '8px',
                  borderRadius: '20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '180px',
                  height: '180px',
                }}
              >
                <QRCode
                  value={agentDetails?.qr_code}
                  fgColor="#6A1B9A"
                  qrStyle="dots"
                  logoImage={ImagesLinks.vrefydQrLogo}
                  removeQrCodeBehindLogo={true}
                  ecLevel="L"
                  style={{
                    width: '162px',
                    height: '165px',
                    borderRadius: '12px',
                  }}
                />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </Modal>
  );
}
